import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Support() {
  const [modal_small, setmodal_small] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [banner, setbanner] = useState([])

  const [form, setform] = useState([])

  const [files, setFiles] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddRequest()
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  useEffect(() => {
    GetAllBanners()
  }, [])

  const AddRequest = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("hospitalId", datasd)
    dataArray.append("subject", form.subject)
    dataArray.append("description", form.description)

    for (let i = 0; i < files.length; i++) {
      dataArray.append("file", files[i])
    }
    axios
      .post(URLS.AddRequestprofile, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetAllBanners()
            clearForm()
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const clearForm = () => {
    setform({
      subject: "",
      description: "",
    })
    setFiles("")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.GetRequestprofile,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.rqst)
        setIsLoading(false)
      })
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetRequestprofileSearch + `${e.target.value}`,
        { hospitalId: datasd },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.rqst)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const getpopup = () => {
    tog_small()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Actin" breadcrumbItem="Request For Profile" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardHeader className="bg-white">
                      <CardTitle>
                        <Button
                          onClick={() => {
                            getpopup()
                          }}
                          className="mr-2"
                          style={{
                            padding: "6px",
                            margin: "3px",
                            background: "#b30706",
                          }}
                        >
                          Request
                        </Button>
                      </CardTitle>
                    </CardHeader>

                    <CardBody>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "500px" }}
                        >
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Ticket Id</th>
                                <th>Date / Time</th>
                                <th>Subject</th>
                                <th>Upload</th>
                                <th>Description</th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                  <td>{data.ticketId}</td>
                                  <td>
                                    {data.logCreatedDate.slice(0, 10)}/
                                    {data.logCreatedDate.slice(14, 19)}
                                  </td>
                                  <td>{data.subject}</td>
                                  <td>
                                    <img
                                      style={{ width: "100px" }}
                                      src={URLS.Base + data.file}
                                    />
                                  </td>
                                  <td>{data.description}</td>
                                  <td>
                                    {data.status == "Rejected" ? (
                                      <>
                                        <span className="badge bg-danger">
                                          {data.status}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {data.status == "Solved" ? (
                                          <>
                                            <span className="badge bg-success">
                                              {data.status}
                                            </span>
                                          </>
                                        ) : (
                                          <span className="badge bg-warning">
                                            {data.status}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="lg"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Request For Profile
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit(e)
              }}
            >
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Subject <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Subject"
                    required
                    value={form.subject}
                    name="subject"
                    onChange={e => {
                      handlechange(e)
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    File <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description"
                    required
                    value={form.description}
                    name="description"
                    onChange={e => {
                      handlechange(e)
                    }}
                  />
                </div>
              </Col>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Support
