import React from "react"
import {
  Container,
  Col,
  Card,
  CardBody,
  Row,
  Button,
  Input,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"

function DoctorSchedule() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Actin"
            breadcrumbItem="Home Tests Making List"
          />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Col>
                    <Link to="/AddTestsMaking">
                      <Button color="primary" className="m-2">
                        New Home Tests Making{" "}
                        <i className="bx bx-plus-circle"></i>
                      </Button>
                    </Link>
                  </Col>
                  <Row>
                    <Col md={12}>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          className="form-control"
                          placeholder="Search.."
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <div className="table-responsive" style={{height:"500px"}}>
                      <Table className="table table-bordered mb-2 mt-3">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Tests Name</th>
                            <th>Price </th>
                            <th>Employee Name / Id</th>
                            <th>Date</th>
                            <th>From Time</th>
                            <th>To Time</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Blood Test</td>
                            <td>500</td>
                            <td>Kavya / Apollo112</td>
                            <td>22-2-2022</td>
                            <td>09:00 am</td>
                            <td>05:00 pm</td>
                            <td>
                              <Link to="/EditTestsMaking">
                                <Button
                                  className="mr-2"
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="success"
                                  outline
                                >
                                  <i className="bx bx-edit "></i>
                                </Button>{" "}
                              </Link>
                              <Button
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                color="danger"
                                outline
                              >
                                <i className="bx bx-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DoctorSchedule
