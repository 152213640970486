import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function DigitalBrochure() {
  const [form, setform] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    GetAllBroucher()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetAllBroucher = () => {
    var token = datas

    axios
      .post(
        URLS.GetContactUs,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.contactus)
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Actin" breadcrumbItem="Contact Us " />
          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardHeader className="bg-white"></CardHeader>

                    <CardBody>
                      <Row>
                        <div className="m-5">
                          <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                            <Table hover className="table table-bordered mb-4">
                              <thead>
                                <tr className="text-center">
                                  <th>Office Name</th>
                                  <td>{form.officeName}</td>
                                </tr>
                                <tr className="text-center">
                                  <th>Office Email</th>
                                  <td>{form.email}</td>
                                </tr>
                                <tr className="text-center">
                                  <th>Office Phone Number</th>
                                  <td>{form.contactNumber}</td>
                                </tr>
                                <tr className="text-center">
                                  <th>Office Alternate Phone Number</th>
                                  <td>{form.alternateContactNumber}</td>
                                </tr>
                                <tr className="text-center">
                                  <th>Office Location</th>
                                  <td>{form.officeLocation}</td>
                                </tr>
                                <tr className="text-center">
                                  <th>Pincode </th>
                                  <td>{form.pincode}</td>
                                </tr>
                              </thead>
                            </Table>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>{" "}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DigitalBrochure
