import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Table, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer } from "react-toastify"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [isLoading, setIsLoading] = useState(true)

  const [Actin, setActin] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const Actinid = data => {
    sessionStorage.setItem("bookingids", data._id)
    history.push("/TestsView")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.getAllbookings,
        { type: "Act Instant", hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.booking)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.getAllbookingsSearch + `${e.target.value}`,
        { type: "Act Instant", hospitalId: datasd },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.booking)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="ACTIN"
            breadcrumbItem="ACT-INstant Booking List"
          />
          <Row>
            <Col>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col></Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>ACTIN Booking Id</th>
                              <th>ACTIN User Id</th>
                              <th>User Name</th>
                              <th>Contact No</th>
                              <th>Test Details</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>{data.bookingCode}</td>
                                <td>{data.userCode}</td>
                                <td>{data.customerName}</td>
                                <td>{data.customerPhone}</td>
                                <td className="text-start">
                                  {data.items.map((data, i) => (
                                    <>
                                      <p key={i}>
                                        {i + 1}){data.itemName}
                                      </p>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {data.bookingDate}/{data.bookingTime}
                                </td>
                                <td>
                                  {data.status == "canceled" ||
                                  data.status == "failed" ? (
                                    <>
                                      <span className="badge bg-danger">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {data.status == "completed" ? (
                                        <>
                                          <span className="badge bg-success">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <span className="badge bg-warning">
                                          {data.status}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    onClick={() => {
                                      Actinid(data)
                                    }}
                                    color="info"
                                    outline
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>{" "}
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
