import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Input,
  Button,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { Link, useHistory } from "react-router-dom"
import gig from "../../assets/images/loders.gif"

function DoctorSchedule() {
  const [Actin, setActin] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  useEffect(() => {
    GetHospitals()
    datass()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetallHomeserviceslots,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.booking)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid = data => {
    sessionStorage.setItem("Timeslotids", data._id)
    history.push("/EditHomeServiceSlotMaking")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  const Search = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
    const token = datas
    axios
      .post(
        URLS.GetTestSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setActin(res.data.tests)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const DelectDistrict = data => {
    var token = datas

    const remid = {
      homeserviceId: data._id,
    }
    axios
      .post(URLS.DeleteHomeServicesTimeSlots, remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetHospitals()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDeletes = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DelectDistrict(data)
    }
  }

  const [from2, setfrom2] = useState([])

  useEffect(() => {
    GetHolidaylists()
  }, [])

  const GetHolidaylists = () => {
    var token = datas
    axios
      .post(
        URLS.GetHolidayHomeServiceTimeSlots,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setfrom2(res.data.booking)
        setIsLoading(false)
      })
  }

  const ActinidS = data => {
    sessionStorage.setItem("slotid", data._id)
    history.push("/EditHomeServiceHoliday")
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteState(data)
    }
  }
  const DeleteState = data => {
    var token = datas
    const remid = {
      _id: data._id,
    }
    axios
      .post(URLS.DeleteHomeServiceHolidayTimeSlots, remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetHolidaylists()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Actin"
            breadcrumbItem="Home Service Slot Making"
          />

          <Row>
            <Col md={12}>
              <Card>
              {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                <CardBody>
                  <Col>
                    <Link to="/AddHomeServiceSlotMaking">
                      <Button color="primary" className="m-2">
                        Home Service Slot Making{" "}
                        <i className="bx bx-plus-circle"></i>
                      </Button>
                    </Link>
                  </Col>
                  <Row>
                    <Col md={12}>
                      <div style={{ float: "right" }}>
                        {/* <Input
                          type="search"
                          name="search"
                          className="form-control"
                          placeholder="Search.."
                        /> */}
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <div className="table-responsive" style={{height:"500px"}}>
                      <Table className="table table-bordered mb-2 mt-3">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Home Service Name</th>
                            <th>HCU Price </th>
                            <th>Employee Id</th>
                            <th>Employee Name</th>
                            <th>From Time </th>
                            <th>To Time</th>
                            <th>Slot</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key} className="text-center">
                              <td>{(pageNumber - 1) * 20 + key + 21}</td>
                              <td>{data.homeserviceName}</td>
                              <td>{data.hcuPrice}</td>
                              <td>{data.employeeId}</td>
                              <td>{data.employeeName}</td>

                              <td>
                                {data.timings.map((data, i) => (
                                  <>
                                    {/* <li key={i}>{data.fromtime} </li> */}
                                    <Button
                                      key={key}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="primary" style={{width:'70px'}}
                                    >
                                      {data.fromtime}
                                    </Button>
                                    <br></br>
                                  </>
                                ))}
                              </td>

                              <td>
                                {data.timings.map((data, i) => (
                                  <>
                                    {/* <li key={i}>{data.totime}</li> */}
                                    <Button
                                      key={key}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="primary" style={{width:'70px'}}
                                    >
                                      {data.totime}
                                    </Button>
                                    <br></br>
                                  </>
                                ))}
                              </td>
                              <td>{data.timings.length}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    Actinid(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="success"
                                >
                                  <i
                                    style={{ fontSize: " 14px" }}
                                    className="bx bx-edit"
                                  ></i>
                                </Button>

                                <Button
                                  onClick={() => {
                                    manageDeletes(data)
                                  }}
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
                </>
                )}
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
              {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                <CardBody>
                  <Col>
                    <Link to="/AddHomeServiceHoliday">
                      <Button color="primary" className="m-2">
                      Home Service Holiday Slot Making
                        <i className="bx bx-plus-circle"></i>
                      </Button>
                    </Link>
                  </Col>

                  <div>
                    <div className="table-responsive" style={{height:"500px"}}>
                      <Table className="table table-bordered mb-2 mt-3">
                        <thead>
                          <tr className="text-center">
                            <th>S.No</th>
                            <th>Date</th>
                            <th>From Time</th>
                            <th>To Time</th>
                            <th>Slots</th>
                            <th style={{ width: "100px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {from2.map((data, key) => (
                            <tr key={key} className="text-center">
                              <td>{key + 1}</td>
                              <td>{data.date}</td>
                              <td>
                                {data.timings.map((data, key) => (
                                  <>
                                    {/* <li key={key} className="mt-2">
                                      {data.fromtime}
                                    </li> */}
                                    <Button
                                      key={key}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="primary" style={{width:'70px'}}
                                    >
                                      {data.fromtime}
                                    </Button>
                                    <br></br>
                                  </>
                                ))}
                              </td>
                              <td>
                                {data.timings.map((data, key) => (
                                  <>
                                    {/* <li key={key} className="mt-2">
                                      {data.totime}
                                    </li> */}
                                    <Button
                                      key={key}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="primary" style={{width:'70px'}}
                                    >
                                      {data.totime}
                                    </Button>
                                    <br></br>
                                  </>
                                ))}
                              </td>
                              <td>
                              {data.timings.length}
                              </td>
                              <td>
                                <Button
                                  onClick={() => {
                                    ActinidS(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="success"
                                >
                                  <i
                                    style={{ fontSize: " 14px" }}
                                    className="bx bx-edit"
                                  ></i>
                                </Button>
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  style={{
                                    padding: "6px",
                                    margin: "3px",
                                  }}
                                  color="danger"
                                  outline
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
                </>
                )}
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DoctorSchedule
