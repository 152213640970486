import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
  Button,
  Modal,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"

function Ventures() {
  const [Actin, setActin] = useState([])

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [form, setform] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const getActin = () => {
    var token = datas
    axios
      .post(
        URLS.GetVenture,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.ventureResult)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid = data => {}

  const Actinid1 = data => {}

  const Search = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    console.log(token)

    axios
      .post(
        URLS.GetVentureSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.InActiveVenture + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getActin()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const getpopup = () => {
    tog_small()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="B2B List" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form1.search}
                          onChange={Search}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>

                  <h5> B2B List</h5>
                  <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                    <Table hover className="table table-bordered mb-4 mt-5">
                      <thead>
                        <tr className="text-center">
                          <th>Sl No</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Test Name</th>
                          <th>Consolidated Place</th>
                          <th> Patient Ward No</th>
                          <th>Name of patient</th>
                          <th>Contact Number</th>
                          <th>Age</th>
                          <th>Gender</th>
                          <th>Recommended By Doctor</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>

                          <td>21-02-2023</td>
                          <td>12:00</td>
                          <td>Bloodtest</td>
                          <td>-</td>
                          <td>10</td>
                          <td>Santhosh</td>
                          <td>888777444</td>
                          <td>21</td>
                          <td>Male</td>
                          <td>javeen</td>

                          <td>
                            <Button
                              style={{
                                padding: "6px",
                                margin: "3px",
                              }}
                              onClick={() => {
                                getpopup()
                              }}
                              color="danger"
                              outline
                            >
                              <i className="bx bxs-file-doc"></i>
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>21-02-2023</td>
                          <td>12:00</td>
                          <td>Bloodtest</td>
                          <td>-</td>
                          <td>10</td>
                          <td>Santhosh</td>
                          <td>888777444</td>
                          <td>21</td>
                          <td>Male</td>
                          <td>javeen</td>

                          <td>
                            <Button
                              style={{
                                padding: "6px",
                                margin: "3px",
                              }}
                              onClick={() => {
                                getpopup()
                              }}
                              color="danger"
                              outline
                            >
                              <i className="bx bxs-file-doc"></i>
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>21-02-2023</td>
                          <td>12:00</td>
                          <td>Bloodtest</td>
                          <td>-</td>
                          <td>10</td>
                          <td>Santhosh</td>
                          <td>888777444</td>
                          <td>21</td>
                          <td>Male</td>
                          <td>javeen</td>

                          <td>
                            <Button
                              style={{
                                padding: "6px",
                                margin: "3px",
                              }}
                              onClick={() => {
                                getpopup()
                              }}
                              color="danger"
                              outline
                            >
                              <i className="bx bxs-file-doc"></i>
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>21-02-2023</td>
                          <td>12:00</td>
                          <td>Bloodtest</td>
                          <td>-</td>
                          <td>10</td>
                          <td>Santhosh</td>
                          <td>888777444</td>
                          <td>21</td>
                          <td>Male</td>
                          <td>javeen</td>

                          <td>
                            <Button
                              style={{
                                padding: "6px",
                                margin: "3px",
                              }}
                              onClick={() => {
                                getpopup()
                              }}
                              color="danger"
                              outline
                            >
                              <i className="bx bxs-file-doc"></i>
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Add Report
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label>
                  Upload Bills <span className="text-danger">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Description"
                  required
                  name="title"
                  pattern="[a-zA-Z0-9\s.,$@%*]+"
                />
              </div>

              <div className="mb-3">
                <label>
                  Upload Report <span className="text-danger">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Description"
                  required
                  name="title"
                  pattern="[a-zA-Z0-9\s.,$@%*]+"
                />
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button
                  className="m-1"
                  color="info"
                  onClick={() => {
                    getpopup4()
                  }}
                >
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
