import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactEcharts from "echarts-for-react"
import axios from "axios"
import { URLS } from "../../Url"
import profileImg from "../../assets/images/profile-img.png"
import gig from "../../assets/images/loders.gif"

const Dashboard = () => {
  const [form, setform] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [Calendar, setCalendar] = useState([])

  const [TestAthome, setTestAthome] = useState([])

  const [Investigations, setInvestigations] = useState([])

  const [Constultation, setConstultation] = useState([])

  const [Homeservice, setHomeservice] = useState([])

  const [Health, setHealth] = useState([])

  useEffect(() => {
    GetSettings()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const GetSettings = () => {
    var token = datas

    axios
      .post(
        URLS.GetDashboard,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.data)
        setCalendar(res.data.data.claendardata)
        setTestAthome(res.data.data.homegraphsdata)
        setConstultation(res.data.data.doctorgraphdata)
        setInvestigations(res.data.data.investigatgraphata)
        setHomeservice(res.data.data.homeservicesgraphsdata)
        setHealth(res.data.data.healeth)
        setIsLoading(false)
      })
  }

  const reports = [
    {
      title: "Tests at Home ",
      iconClass: "bx-copy-alt",
      description: form.testatHome,
    },
    {
      title: "Investigations at HCU",
      iconClass: "bx-purchase-tag-alt",
      description: form.investigationCount,
    },
    {
      title: "Consultation",
      iconClass: "bx-archive-in",
      description: form.consultationCount,
    },
    {
      title: "Home Services ",
      iconClass: "bx-archive-in",
      description: form.homeservicesCount,
    },
  ]

  const reports2s = [
    {
      title: "Total Bookings ",
      iconClass: "bx-copy-alt",
      description: form.totalCount,
    },

    {
      title: "Slot Bookings",
      iconClass: "bx-purchase-tag-alt",
      description: form.testatHome,
    },
    {
      title: "ACT-INstant Bookings",
      iconClass: "bx-archive-in",
      description: form.instantCount,
    },
    {
      title: "Emergency Bookings",
      iconClass: "bx-copy-alt",
      description: form.emergencyCount,
    },
  ]

  const reports1 = [
    { title: "CGHS", iconClass: "bx-copy-alt", description: Health.CGHSvalue },
    {
      title: "EHS",
      iconClass: "bx-purchase-tag-alt",
      description: Health.EHSvalue,
    },
    {
      title: "AAROGYASRI",
      iconClass: "bx-archive-in",
      description: Health.AAROGYASRIvalue,
    },
    {
      title: "PMJAY",
      iconClass: "bx-archive-in",
      description: Health.PMJAYvalue,
    },
    {
      title: "ECHS",
      iconClass: "bx-archive-in",
      description: Health.EHSvalue,
    },
    { title: "ESI", iconClass: "bx-archive-in", description: Health.ESIvalue },
    {
      title: "Pvt Insurance",
      iconClass: "bx-archive-in",
      description: Health.PvtInsurancevalue,
    },
    {
      title: "General Public",
      iconClass: "bx-archive-in",
      description: Health.GeneralPublicvalue,
    },
  ]

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["TestAthome"],
      textStyle: {
        color: ["#74788d"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561"],
    series: [
      {
        name: "Total",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: TestAthome,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  const options1 = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["Investigations At HEC"],
      textStyle: {
        color: ["#74788d"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561"],
    series: [
      {
        name: "Total",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: Investigations,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  const options2 = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["CT", "MRI", "Ultra Sound"],
      textStyle: {
        color: ["#74788d"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561"],
    series: [
      {
        name: "Total",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: Constultation,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  const options3 = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["Home Services"],
      textStyle: {
        color: ["#74788d"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561"],
    series: [
      {
        name: "Total",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: Homeservice,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  const eventRender = info => {
    const now = new Date()
    if (info.event.start < now) {
      info.el.classList.add("past-event")
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  var Roles = data.user?.permissions[0]

  const Roles1 = data.user?.serviceList.includes("Test At Home")
  const Roles2 = data.user?.serviceList.includes("Investigations at HCU")
  const Roles3 = data.user?.serviceList.includes("Consultations")
  const Roles4 = data.user?.serviceList.includes("Home Services")

  return (
    <React.Fragment>
      <div className="page-content">
        {Roles?.Dashview === true || Roles?.accessAll === true ? (
          <>
            <Container fluid>
              <Breadcrumbs title={"Actin"} breadcrumbItem={"Dashboard"} />
              {isLoading == true ? (
                <>
                  <Card>
                    <CardBody>
                      <div
                        style={{ zIndex: "9999999999999", height: "450px" }}
                        className="text-center mt-5 pt-5"
                      >
                        {" "}
                        <img src={gig} height="140px"></img>
                        <div>Loading......</div>
                      </div>
                    </CardBody>
                  </Card>
                </>
              ) : (
                <>
                  <Row>
                    <Col xl="4">
                      <Card className="overflow-hidden">
                        <div className="bg-primary bg-soft">
                          <Row>
                            <Col xs="7">
                              <div className="text-primary p-3">
                                <h5 className="text-primary">Welcome Back !</h5>
                              </div>
                            </Col>
                            <Col xs="5" className="align-self-end">
                              <img
                                src={profileImg}
                                alt=""
                                className="img-fluid"
                              />
                            </Col>
                          </Row>
                        </div>
                        <CardBody className="pt-0">
                          <Row>
                            <Col sm="4">
                              <div className="avatar-md profile-user-wid mb-4">
                                <img
                                  src={URLS.Base + form.logo}
                                  alt=""
                                  className="img-thumbnail rounded-circle"
                                />
                              </div>
                              <h5 className=" text-truncate">
                                {form.hospitalName}{" "}
                              </h5>
                              <h6 className="mb-0 text-truncate ">
                                <i
                                  style={{ fontSize: "20px" }}
                                  className="bx bx-smile  text-warning"
                                ></i>{" "}
                                 {form.hospitalSmiles}
                              </h6>
                            </Col>

                            <Col sm="8">
                              <div className="pt-4">
                                <Row>
                                  <Col xs="12">
                                    <h5 className="font-size-15">Address :</h5>
                                    <p className="text-muted mb-0">
                                      {form.address}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl="8">
                      <Row>
                        {reports.map((report, key) => (
                          <Col md="6" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">
                                      {report.title}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.description}
                                    </h4>
                                  </div>
                                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-primary">
                                      <i
                                        className={
                                          "bx " +
                                          report.iconClass +
                                          " font-size-24"
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col md={12}>
                      <Row>
                        {reports2s.map((report, key) => (
                          <Col md="3" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">
                                      {report.title}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.description}
                                    </h4>
                                  </div>
                                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-primary">
                                      <i
                                        className={
                                          "bx " +
                                          report.iconClass +
                                          " font-size-24"
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col xl="12">
                      <Row>
                        <h5>Health Schemes Count</h5>
                        {reports1.map((report, key) => (
                          <Col md="3" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">
                                      {report.title}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.description}
                                    </h4>
                                  </div>
                                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-primary">
                                      <i
                                        className={
                                          "bx " +
                                          report.iconClass +
                                          " font-size-24"
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    {Roles1 === false ? (
                      ""
                    ) : (
                      <>
                        <Col md="6">
                          <Card>
                            <CardBody>
                              <h5>Tests (NUMBER)</h5>
                              <ReactEcharts
                                style={{ height: "350px" }}
                                option={options}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    )}{" "}
                    {Roles2 === false ? (
                      ""
                    ) : (
                      <>
                        <Col md="6">
                          <Card>
                            <CardBody>
                              <h5>Investigations at HCU </h5>
                              <ReactEcharts
                                style={{ height: "350px" }}
                                option={options1}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    )}{" "}
                    {Roles3 === false ? (
                      ""
                    ) : (
                      <>
                        <Col md="6">
                          <Card>
                            <CardBody>
                              <h5>Consultation (NUMBER)</h5>
                              <ReactEcharts
                                style={{ height: "350px" }}
                                option={options2}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    )}{" "}
                    {Roles4 === false ? (
                      ""
                    ) : (
                      <>
                        <Col md="6">
                          <Card>
                            <CardBody>
                              <h5>Home Services (NUMBER)</h5>
                              <ReactEcharts
                                style={{ height: "350px" }}
                                option={options3}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row>
                    <Col xl={12}>
                      <Card>
                        <CardBody>
                          <div>
                            <FullCalendar
                              plugins={[dayGridPlugin]}
                              initialView="dayGridMonth"
                              events={Calendar}
                              eventRender={eventRender}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  )
}

export default Dashboard
