import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Modal,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"
import classnames from "classnames"
import gig from "../../assets/images/loders.gif"
import { ToastContainer, toast } from "react-toastify"
import { PDFDocument } from "pdf-lib"
import fileSaver from "file-saver"

function ViewAcceptingInvestigation() {
  const [Booking, setBooking] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [User, setUser] = useState([])

  const handleUser = e => {
    let myUser = { ...User }
    myUser[e.target.name] = e.target.value
    setUser(myUser)
  }

  const history = useHistory()

  useEffect(() => {
    GetOneActins()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datas1 = data.user

  const Actinid = sessionStorage.getItem("bookingidsinv")

  const GetOneActins = () => {
    const data = {
      bookingId: Actinid,
    }

    var token = datas
    axios
      .post(URLS.getinvestigationsBookingOneView, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setBooking(res.data.bookingstest)
        setIsLoading(false)
      })
  }

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [modal_small2, setmodal_small2] = useState(false)

  function tog_small2() {
    setmodal_small2(!modal_small2)
  }

  const [modal_small1, setmodal_small1] = useState(false)

  const [form2, setform2] = useState([])

  const [modal_small3, setmodal_small3] = useState(false)

  const [form3, setform3] = useState([])

  function tog_small3() {
    setmodal_small3(!modal_small3)
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const getpopup = data => {
    tog_small()
    setform1(data)
  }

  const getpopup1 = data => {
    tog_small1()
    setform(data)
    var user = datas1
    setUser(user)
  }

  const getpopup2 = data => {
    tog_small2()
    setform2(data)
  }

  const getpopup3 = data => {
    tog_small3()
    setform3(data.file)
  }

  const [form, setform] = useState([])

  const [form1, setform1] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editbookings()
  }

  const Editbookings = () => {
    var token = datas

    const dataArray = {
      bookingId: form._id,
      employeeName: User.userName,
      employeeId: User.userCode,
      hcuBookingId: form.hcuBookingId,
      hcuUserId: form.hcuUserId,
      customerId: form.customerId,
      familymemberId: form.familymemberid,
      otp: form.otp,
    }
    axios
      .post(URLS.AcceptInvestigationsBookingRequest, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            setmodal_small1(false)
            toast(res.data.message)
            GetOneActins()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit = e => {
    e.preventDefault()
    EditReports()
  }

  const [files1, setFiles1] = useState([])

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "pdf" || type == "PDF") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const EditReports = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("bookingId", form1._id)

    dataArray.append("familymemberId", form1.familymemberid)

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("reportfile", files1[i])
    }

    axios
      .post(URLS.UploadinvestigationsBookingReports, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetOneActins()
            stopStopwatch()
            // getpopup4()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [activeTab, setActiveTab] = useState(0)

  const toggleTab = tabIndex => {
    if (activeTab !== tabIndex) {
      setActiveTab(tabIndex)
    }
  }

  const modifyPdf = async datas => {
    const data = datas
    const existingPdfBytes = await fetch(data).then(res => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    const watermarkImageBytes = await fetch(
      "https://api.actinplus.com/uploads/logoSmallTilt.png"
    ).then(res => res.arrayBuffer())
    const watermarkImage = await pdfDoc.embedPng(watermarkImageBytes)

    const pages = pdfDoc.getPages()

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i]
      const { width, height } = page.getSize()

      const x = (width - 300) / 2
      const y = (height - 100) / 2

      page.drawImage(watermarkImage, {
        x,
        y,
        width: 300,
        height: 100,
        opacity: 0.1,
      })
    }

    const modifiedPdfBytes = await pdfDoc.save()
    fileSaver.saveAs(
      new Blob([modifiedPdfBytes], { type: "application/pdf" }),
      "pdf-lib_modification_example.pdf"
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="ACTIN"
            breadcrumbItem="View Accepting Investigation"
          />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>

          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <div>
                <Row>
                  <Nav pills className="navtab-bg nav-justified">
                    {Booking.map((data, index) => (
                      <>
                        <Row>
                          {" "}
                          <Col md={12}>
                            <NavItem key={index}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === index,
                                })}
                                onClick={() => toggleTab(index)}
                              >
                                <img
                                  src={URLS.Base + data.image}
                                  alt=""
                                  className="avatar-md1 rounded-circle img-thumbnail"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                                <b className="m-3">{data.name}</b>
                              </NavLink>
                            </NavItem>{" "}
                          </Col>
                        </Row>
                      </>
                    ))}
                  </Nav>
                </Row>

                <TabContent activeTab={activeTab} className="pt-3 text-muted">
                  {Booking.map((data, index) => (
                    <TabPane key={index} tabId={index}>
                      <Row>
                        <Col md="12">
                          <Card>
                            <CardBody>
                              <h5 className="text-primary">Timing :-</h5>

                              <div className="table-rep-plugin table-responsive">
                                <Table hover className="table table-bordered">
                                  <thead>
                                    <tr className="text-center">
                                      <th>Slot Date/Time </th>
                                      <th>Timer </th>
                                      <th>Otp Verify Date/Time </th>
                                      <th>Report Bill Generation Time</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="text-center">
                                      <td>
                                        {data.slotDate}/ {data.slotTime}
                                      </td>
                                      <td>
                                        <p style={{ color: "red" }}>
                                          {data.bookingTestHrs}
                                        </p>
                                      </td>
                                      <td>
                                        {data.otherTimings.otpVerifyDate} -{" "}
                                        {data.otherTimings.otpVerifyTime}
                                      </td>
                                      <td>
                                        {data.otherTimings.completedDate} -{" "}
                                        {data.otherTimings.completedTime}
                                      </td>
                                      <td>
                                        {" "}
                                        {data.status == "canceled" ||
                                        data.status == "failed" ? (
                                          <>
                                            <span className="badge bg-danger">
                                              {data.status}
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            {data.status == "completed" ? (
                                              <>
                                                <span className="badge bg-success">
                                                  {data.status}
                                                </span>
                                              </>
                                            ) : (
                                              <span className="badge bg-warning">
                                                {data.status}
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={4}>
                          <Card>
                            <CardBody>
                              <h5 className="text-primary"> User Details :</h5>
                              <Col md={12}>
                                <div className="text-center">
                                  <div className="avatar-md1 profile-user-wid mb-4 mt-4">
                                    <span className="ml-5">
                                      <img
                                        src={URLS.Base + data.image}
                                        alt=""
                                        className="avatar-md1 rounded-circle img-thumbnail"
                                        style={{
                                          height: "120px",
                                          width: "120px",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <h5 className="mt-1 mb-1">{data.name}</h5>
                                </div>
                              </Col>

                              <ul className="list-unstyled mt-2">
                                <li>
                                  <div className="d-flex">
                                    <i className="bx bx-phone text-primary fs-4"></i>
                                    <div className="ms-3">
                                      <h6 className="fs-14 mb-2">Phone</h6>
                                      <p className="text-muted fs-14 mb-0">
                                        {data.phone}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li className="mt-3">
                                  <div className="d-flex">
                                    <i className="bx bx-mail-send text-primary fs-4"></i>
                                    <div className="ms-3">
                                      <h6 className="fs-14 mb-2">Email</h6>
                                      <p className="text-muted fs-14 mb-0">
                                        {data.email}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li className="mt-3">
                                  <div className="d-flex">
                                    <i className="bx bx-error-circle text-primary fs-4"></i>
                                    <div className="ms-3">
                                      <h6 className="fs-14 mb-2">
                                        Gender / Age{" "}
                                      </h6>
                                      <p className="text-muted fs-14 text-break mb-0">
                                        {data.gender} / {data.age}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li className="mt-3">
                                  <div className="d-flex">
                                    <i className="bx bx-map text-primary fs-4"></i>
                                    <div className="ms-3">
                                      <h6 className="fs-14 mb-2">Location </h6>
                                      <p className="text-muted fs-14 mb-0">
                                        {data.address}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col md={8}>
                          <Card>
                            <CardBody>
                              <Row>
                                <Col md={6}>
                                  <h5 className="text-primary">
                                    {" "}
                                    Slot Details :
                                  </h5>
                                </Col>

                                <Col md={6}>
                                  {data.status == "Approved" ||
                                    data.isUpload == "yes" ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          getpopup(data)
                                        }}
                                        className="mb-3 m-1"
                                        style={{ float: "right" }}
                                        color="primary"
                                      >
                                        <i className="far fa-arrow-alt-circle-left"></i>
                                        Upload Reports
                                      </Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {data.status == "Pending" ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          getpopup1(data)
                                        }}
                                        className="mb-3 m-1"
                                        style={{ float: "right" }}
                                        color="success"
                                      >
                                        <i className="far fa-arrow-alt-circle-left"></i>
                                        Accept Booking
                                      </Button>{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {data.status == "Completed" ? (
                                    <>
                                      {/* <a
                                        href={URLS.Base + data.file}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Button
                                          className="mb-3 m-1"
                                          style={{ float: "right" }}
                                          color="warning"
                                        >
                                          View Report
                                        </Button>
                                      </a> */}
                                      <Button
                                        onClick={() => {
                                          modifyPdf(URLS.Base + data.file)
                                        }}
                                        className="mb-3 m-1"
                                        style={{ float: "right" }}
                                        color="warning"
                                      >
                                        Download Report
                                      </Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>

                              <div className="table-rep-plugin table-responsive">
                                <Table hover className="table table-bordered">
                                  <thead>
                                    <tr className="text-center">
                                      <th>Blood Pressure</th>
                                      <td>{data.userBloodPressure}</td>
                                    </tr>

                                    <tr className="text-center">
                                      <th>Temperature</th>
                                      <td>{data.userBloodTemperature}</td>
                                    </tr>

                                    <tr className="text-center">
                                      <th>PR Two</th>
                                      <td>{data.userPrTwo}</td>
                                    </tr>
                                  </thead>
                                </Table>
                              </div>
                              <h5 className="text-primary mt-2"> ID's :</h5>
                              <Table
                                hover
                                className="table table-bordered mt-2"
                              >
                                <thead>
                                  <tr className="text-center">
                                    <th>ID</th>
                                    <th>Actin</th>
                                    <th>HCU</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="text-center">
                                    <th>User Id</th>
                                    <td>{data.userCode}</td>
                                    <td>{data.hcuUserId}</td>
                                  </tr>
                                  <tr className="text-center">
                                    <th>Booking Id</th>
                                    <td>{data.bookingId}</td>
                                    <td> {data.hcuBookingId}</td>
                                  </tr>
                                  <tr className="text-center">
                                    <th>Collected By</th>
                                    <td>-</td>
                                    <td>
                                      {data.employeeName}/{data.employeeId}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Card>
                            {" "}
                            <CardBody>
                              <h5 className="text-primary">
                                Home Test Details :
                              </h5>
                              <Table
                                hover
                                className="table table-bordered mt-4"
                              >
                                <thead>
                                  <tr className="text-center">
                                    <th>ACTIN Investigation Id</th>
                                    <th>HCU Investigation Id</th>
                                    <th>Investigation Name</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.testsdata.map((datas, key) => (
                                    <tr key={key} className="text-center">
                                      <td>{datas.actintestId}</td>
                                      <td>{datas.hocTestid}</td>
                                      <td>{datas.itemName}</td>
                                      <td>{datas.price}</td>
                                    </tr>
                                  ))}

                                  <tr className="text-center">
                                    <td></td>
                                    <td></td>
                                    <td>Total Amount </td>
                                    <td>
                                      {" "}
                                      {data.testsdata.reduce(
                                        (total, datas) =>
                                          parseInt(total) +
                                          parseInt(datas.price),
                                        0
                                      )}{" "}
                                      /-
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Card className="mb-5">
                        <CardBody>
                          <div>
                            <div className="row">
                              {" "}
                              <div className="col-md-6">
                                <h5 className="mb-4 text-primary">
                                  HCU Review :
                                </h5>
                                <blockquote className="p-4 border-light border rounded mb-4">
                                  <div className="d-flex mt-4">
                                    <img
                                      src={URLS.Base + data.image}
                                      className="avatar-xs me-3 rounded-circle"
                                      alt="img"
                                    />

                                    <div className="flex-grow-1">
                                      <h5 className="mt-0 mb-1 font-size-15">
                                        {data.name}
                                      </h5>
                                      <p className="text-muted">
                                        {data.hospitalReview}
                                      </p>

                                      <div className="text-muted font-size-12">
                                        <i className="far fa-calendar-alt text-primary me-1" />{" "}
                                        {data.slotDate}
                                      </div>
                                    </div>
                                  </div>{" "}
                                </blockquote>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </TabPane>
                  ))}
                </TabContent>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        size="md"
        isOpen={modal_small}
        toggle={() => {
          tog_small()
        }}
        centered
      >
        {" "}
        <Form
          onSubmit={e => {
            handleSubmit(e)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Add Reports : <br></br>
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label>
                Upload Report <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter image"
                name="image"
                required
                onChange={changeHandler1}
              />
            </div>

            <div style={{ float: "right" }}>
              <Button
                type="submit"
                onClick={() => {
                  setmodal_small(false)
                }}
                color="danger"
              >
                Cancel <i className="fas fa-times-circle"></i>
              </Button>
              <Button className="m-1" color="info">
                Submit <i className="fas fa-check-circle"></i>
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        size="md"
        isOpen={modal_small1}
        toggle={() => {
          tog_small1()
        }}
        centered
      >
        <Form
          onSubmit={e => {
            handleSubmit1(e)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Accepting Bookings : <br></br>
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label>
                Employee Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter Employee Name "
                required
                value={User.userName}
                name="userName"
                onChange={e => {
                  handleUser(e)
                }}
              />
            </div>
            <div className="mb-3">
              <label>
                Employee Id <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter Employee Id "
                required
                value={User.userCode}
                name="userCode"
                onChange={e => {
                  handleUser(e)
                }}
              />
            </div>
            <div className="mb-3">
              <label>
                HCU Booking Id
              </label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter HCU Booking Id"
                value={form.hcuBookingId}
                name="hcuBookingId"
                onChange={e => {
                  handleChange(e)
                }}
              />
            </div>
            <div className="mb-3">
              <label>
                User Id 
              </label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter User Id"
                value={form.hcuUserId}
                name="hcuUserId"
                onChange={e => {
                  handleChange(e)
                }}
              />
            </div>
            <div className="mb-3">
              <label>
                Otp <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter Otp"
                required
                value={form.otp}
                name="otp"
                onChange={e => {
                  handleChange(e)
                }}
              />
            </div>
            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small1(false)
                }}
                color="danger"
                type="button"
              >
                Cancel <i className="fas fa-times-circle"></i>
              </Button>
              <Button className="m-1" color="info" type="submit">
                Submit <i className="fas fa-check-circle"></i>
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        size="md"
        isOpen={modal_small2}
        toggle={() => {
          tog_small2()
        }}
        centered
      >
        <Form
          onSubmit={e => {
            handleSubmit1(e)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Reject :
            </h5>
            <button
              onClick={() => {
                setmodal_small2(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label>
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter Description "
                required
                rows={3}
                onChange={e => {
                  handleChange(e)
                }}
                name="description"
                value={form.description}
              />
            </div>

            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small2(false)
                }}
                color="danger"
                type="button"
              >
                Cancel <i className="fas fa-times-circle"></i>
              </Button>
              <Button className="m-1" color="info" type="submit">
                Submit <i className="fas fa-check-circle"></i>
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        size="md"
        isOpen={modal_small3}
        toggle={() => {
          tog_small3()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Report :
          </h5>
          <button
            onClick={() => {
              setmodal_small3(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <a href={URLS.Base + form3} target="_blank" rel="noreferrer">
            <img
              src={URLS.Base + form3}
              alt=""
              style={{
                height: "400px",
                width: "100%",
              }}
            />
          </a>
        </div>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  )
}
export default ViewAcceptingInvestigation
