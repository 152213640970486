import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Input,
  Label,
  Form,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { userForgetPassword } from "../../store/actions"
import profile from "../../assets/images/profile-img.png"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"

import fav from "../../assets/images/favs.png"

const ForgetPasswordPage = props => {
  //meta title
  document.title = "Forget Password"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      dispatch(userForgetPassword(values, props.history))
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  const [form, setform] = useState([])
  let history = useHistory()

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const forgot = () => {
    const dataArray = new FormData()
    dataArray.append("email", form.email)

    axios.post(URLS.forget, dataArray).then(
      res => {
        if (res.status === 200) {
          toast(res.data.message)
          console.log(res.data)
          sessionStorage.setItem("eamilid", res.data.userInfo)
          setform("")
          history.push(
            "/Compareotp",
            localStorage.setItem(
              "tost",
              "OTP has been sent successfully to specified email..!"
            )
          )
        }
      },
      error => {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message)
        }
      }
    )
  }

  const formsubmit = e => {
    e.preventDefault()
    forgot()
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}></Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={fav} alt="" className="rounded-circle" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        formsubmit(e)
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={e => {
                            handleChange(e)
                          }}
                          onBlur={validation.handleBlur}
                          value={form.email}
                          required
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Actin. Design & Developed by <a
                    href="https://digitalraiz.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DigitalRaiz
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
