import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Table,
  Button,
  Label,
  Modal,
  Form,Input
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"
import gig from "../../assets/images/loders.gif"
import jsPDF from "jspdf"
import "jspdf-autotable"

const List = () => {
  const [modal_small, setmodal_small] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [form1, setform1] = useState([])

  const [form2, setform2] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange1 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  const [Actin, setActin] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
    datass()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllServiceProviders,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.serviceProviders)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid = data => {
    sessionStorage.setItem("Doctorid", data._id)
    history.push("/EditHomeServiceProvider")
  }

  const Actinid1 = data => {
    sessionStorage.setItem("Doctorids", data._id)
    history.push("/ViewHomeServiceProvider")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }
  const getpopup = data => {
    setform1(data)
    tog_small()
  }
  const handleSubmit1 = e => {
    e.preventDefault()
    Editstatus()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const Editstatus = () => {
    var token = datas
    const dataArray = {
      cost: form2.cost,
      description: form2.description,
      doctorId: form1._id,
      hospitalId: datasd,
    }

    axios
      .post(URLS.UpdateDoctorprice, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetHospitals()
            toast(res.data.message)
            setmodal_small(false)
            setform2({
              cost: "",
              description: "",
            })
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetAllDoctorSearch + `${e.target.value}`,
        { hospitalId: datasd },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.dctr)
      })
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A3"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "S.No",
        "Doctor Id",
        "Name",
        "Phone",
        "Email",
        "Tariff / Cost",
        "Speciality",
      ],
    ]
    const data = Actin.map((elt, i) => [
      i + 1,
      elt.doctorEmployId,
      elt.firstName + elt.lastName,
      elt.phone,
      elt.email,
      elt.tarrifPrice,
      elt.speciality,
    ])

    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("ServiceProvider_details.pdf")
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Service Provider"
            breadcrumbItem="Service Provider List"
          />

          <Card>
            {isLoading == true ? (
              <>
                <div
                  style={{ zIndex: "9999999999999", height: "420px" }}
                  className="text-center mt-5 pt-5"
                >
                  <img src={gig} height="140px"></img>
                  <div>Loading......</div>
                </div>
              </>
            ) : (
              <>
                <CardBody>
                  <Row>
                    <Col>
                      <Link to="/AddHomeServiceProvider">
                        <Button color="primary" className="m-2">
                          Add Service Provider
                          <i className="bx bx-plus-circle"></i>
                        </Button>
                      </Link>
                      <Button
                          type="button"
                          className="btn btn-info "
                          onClick={exportPDF}
                        >
                          <i className="fas fa-file-pdf"></i> Pdf
                        </Button>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          value={Searchs.search}
                          onChange={Search}
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive" style={{height:"500px"}}>
                    <Table className="table table-bordered mb-2 mt-3">
                      <thead>
                        <tr className="text-center">
                          <th>S.No</th>
                          <th>Employee Id</th>
                          <th>Profile</th>
                          <th>Name </th>
                          <th>Phone </th>
                          <th>Email</th>
                          {/* <th>Location in HCU </th> */}
                          {/* <th>Tariff / Cost </th> */}
                          <th>Speciality </th>
                          <th style={{ width: "100px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <td>{(pageNumber - 1) * 20 + key + 21}</td>
                            <td>{data.doctorEmployId}</td>
                            <td>
                              <img
                                src={URLS.Base + data.employImagelogo}
                                width="80px"
                              ></img>
                            </td>
                            <td>
                              {data.firstName}
                              {data.lastName}
                            </td>
                            <td>{data.phone}</td>
                            <td>{data.email}</td>
                            {/* <td>{data.locationInHoc}</td> */}
                            {/* <td>{data.tarrifPrice}</td> */}
                            <td>{data.speciality}</td>

                            <td>
                              <Button
                                className="mr-2"
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                color="success"
                                outline
                                onClick={() => {
                                  Actinid(data)
                                }}
                              >
                                <i className="bx bx-edit "></i>
                              </Button>

                              <Button
                                className="mr-2 "
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                color="info"
                                outline
                                onClick={() => {
                                  Actinid1(data)
                                }}
                              >
                                <i className="fas fa-eye"></i>
                              </Button>

                              {/* <Button
                                className="mr-2"
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                onClick={() => {
                                  getpopup(data)
                                }}
                                color="danger"
                                outline
                              >
                                <i className="bx bxs-help-circle"></i>
                              </Button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </>
            )}
          </Card>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Home Service Price Edit Request / Remove Request
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <Row>
                  {" "}
                  <Col>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Tariff / Cost <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter  Tariff / Cost"
                        required
                        name="cost"
                        value={form2.cost}
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Description <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Description"
                        required
                        name="description"
                        value={form2.description}
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default List
