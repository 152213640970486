import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Input, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import Moment from "react-moment"
import gig from "../../assets/images/loders.gif"

function Support() {
  const [Actin, setActin] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllpricerequestInvestigation,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.investigationTickets)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetAllpricerequestInvestigationSearch + `${e.target.value}`,
        { hospitalId: datasd },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.investigationTickets)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Actin"
            breadcrumbItem="Investigations Ticket Rasing List"
          />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "500px" }}
                        >
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead>
                              <tr className="text-center">
                                <th>S.No</th>
                                <th>Ticket Id</th>
                                <th>Date / Time</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Topic</th>
                                <th>Status</th>
                                <th>Resolution Date & Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                  <td>{data.ticketId}</td>
                                  <td>
                                    {" "}
                                    <Moment>{data.logCreatedDate}</Moment>
                                  </td>
                                  <td>{data.title}</td>
                                  <td>{data.description}</td>
                                  <td>{data.topic}</td>
                                  <td>
                                    {data.status == "Solved" ? (
                                      <>
                                        <span className="badge bg-success">
                                          {data.status}
                                        </span>
                                      </>
                                    ) : (
                                      <span className="badge bg-warning">
                                        {data.status}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {data.resolvedDateTime == "" ? (
                                      <>-</>
                                    ) : (
                                      <>
                                        <Moment>{data.resolvedDateTime}</Moment>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Support
