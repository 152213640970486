import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"

import ForgetPassword from "pages/Authentication/ForgetPassword"
import Resetpsw from "pages/Authentication/Resetpsw"
import Compareotp from "pages/Authentication/Compareotp"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import HospitalProfile from "pages/Settings/HospitalProfile"

//USERS
import Adduser from "pages/Users/Adduser"
import Users from "pages/Users/Users"
import Edituser from "pages/Users/Edituser"

//DEPAETMENTS
import Departments from "pages/Staff/Departments"
import RolesPremissions from "pages/Staff/RolesPremissions"
import Staff from "pages/Staff/Staff"

//SETTINGS
import Terms from "pages/Settings/Terms"
import Faqs from "pages/Settings/Faqs"
import PrivicyPolicy from "pages/Settings/PrivicyPolicy"
import About from "pages/Settings/About"
import Timeslotlist from "pages/Timeschedule/Timeslotlist"

//Support
import Support from "pages/Support/Support"
import Chat from "pages/Chat/Chat"

//Reports
import HomeServiceReport from "pages/Reports/HomeServiceReport"
import DoctorConsultationReport from "pages/Reports/DoctorConsultationReport"
import AcceptingInvestigationsReport from "pages/Reports/AcceptingInvestigationsReport"
import BookingsTestsReport from "pages/Reports/BookingsTestsReport"

// Doctor
import DoctorSchedule from "pages/Doctor/DoctorSchedule"

import DoctorProfile from "pages/Doctor/DoctorProfile"
import AddDoctor from "pages/Doctor/AddDoctor"
import AddTimeslot from "pages/Timeschedule/AddTimeslot"

import AddTest from "pages/Tests/AddTest"
import TestList from "pages/Tests/TestList"
import ViewUser from "pages/Users/ViewUser"
import TestsView from "pages/Tests/TestsView"
import Tests from "pages/Tests/Tests"
import AddBookingTest from "pages/Tests/AddBookingTest"

import AcceptingInvestigation from "pages/Timeschedule/AcceptingInvestigation"
import ViewAcceptingInvestigation from "pages/Timeschedule/ViewAcceptingInvestigation"

import AddHomeServiceSlotMaking from "pages/HomeService/AddHomeServiceSlotMaking"
import ViewHomeSlotService from "pages/HomeService/ViewHomeSlotService"
import HomeServiceSlotList from "pages/HomeService/HomeServiceSlotList"

import AddB2B from "pages/B2B/AddB2B"
import B2BList from "pages/B2B/B2BList"

import ProfileUpdateRequest from "pages/Support/ProfileUpdateRequest"

import Consultation from "pages/Users/Consultation"

import DoctorView from "pages/Doctor/DoctorView"

import HomeService from "pages/HomeService/HomeService"

import AddTestsMaking from "pages/Tests/AddTestsMaking"

import TestsMaking from "pages/Tests/TestsMaking"

import EditTestsMaking from "pages/Tests/EditTestsMaking"

import Investigationlist from "pages/Timeschedule/Investigationlist"

import AddInvestigationlist from "pages/Timeschedule/AddInvestigationlist"

import RefundPolicy from "pages/Settings/RefundPolicy"

import TestRequest from "pages/Tests/TestRequest"

import Investigationrequest from "pages/Timeschedule/Investigationrequest"

import AddHomeService from "pages/HomeService/AddHomeService"

import HomeServiceList from "pages/HomeService/HomeServiceList"

import HomeServiceRequest from "pages/HomeService/HomeServiceRequest"

import ContactUs from "pages/Settings/ContactUs"

import TicketsRasingList from "pages/Settings/TicketsRasingList"

import EditTimeSlot from "pages/Timeschedule/EditTimeSlot"

import EditHomeServiceSlotMaking from "pages/HomeService/EditHomeServiceSlotMaking"

import TestRequestlist from "pages/Tests/TestRequestlist"

import Investigationrequestlist from "pages/Timeschedule/Investigationrequestlist"

import HomeServiceRequestlist from "pages/HomeService/HomeServiceRequestlist"

import EditDoctor from "pages/Doctor/EditDoctor"

import Payments from "pages/Payments/Payments"

import EmergencyBookings from "pages/Tests/EmergencyBookings"

import Actinstant from "pages/Tests/Actinstant"

import Packages from "pages/Packages/Packages"

import AddPackages from "pages/Packages/AddPackages"

import AddRoles from "pages/Staff/AddRoles"

import EditRoles from "pages/Staff/EditRoles"

import InvestigationsTickets from "pages/Settings/InvestigationsTickets"

import ConsultationTickets from "pages/Settings/ConsultationTickets"

import HomeServiceTickets from "pages/Settings/HomeServiceTickets"

import AddInvestigationHolidaySlot from "pages/Timeschedule/AddInvestigationHolidaySlot"

import EditInvestigationHolidaySlot from "pages/Timeschedule/EditInvestigationHolidaySlot"

import AddHomeServiceHoliday from "pages/HomeService/AddHomeServiceHoliday"
import EditHomeServiceHoliday from "pages/HomeService/EditHomeServiceHoliday"

import AddDoctorHolidayList from "pages/Users/AddDoctorHolidayList"
import EditDoctorHolidayList from "pages/Users/EditDoctorHolidayList"

import PackagesBookings from "pages/PackagesBookings/PackagesBookings"
import ViewPackagesBookings from "pages/PackagesBookings/ViewPackagesBookings"

import PackagesTicketRasinglist from "pages/Settings/PackagesTicketRasinglist"

import DeleteAccount from "pages/Settings/DeleteAccount"

import ViewPayment from "pages/Payments/ViewPayment"

import PackagesReport from "pages/Reports/PackagesReport"
import ActinstantReport from "pages/Reports/ActinstantReport"
import EmergencyReport from "pages/Reports/EmergencyReport"

import EditPackages from "pages/Packages/EditPackages"

import Sample from "pages/Settings/Sample"

import AddHomeServiceProvider from "pages/HomeService/AddHomeServiceProvider"
import EditHomeServiceProvider from "pages/HomeService/EditHomeServiceProvider"
import HomeServiceProviderList from "pages/HomeService/HomeServiceProviderList"
import ViewHomeServiceProvider from "pages/HomeService/ViewHomeServiceProvider"

const authProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/AddPackages", component: AddPackages },
  { path: "/EditPackages", component: EditPackages },
  { path: "/Packages", component: Packages },

  { path: "/ViewHomeServiceProvider", component: ViewHomeServiceProvider },

  { path: "/AddHomeServiceProvider", component: AddHomeServiceProvider },
  { path: "/EditHomeServiceProvider", component: EditHomeServiceProvider },
  { path: "/HomeServiceProviderList", component: HomeServiceProviderList },

  { path: "/ViewPayment", component: ViewPayment },

  { path: "/PackagesBookings", component: PackagesBookings },
  { path: "/ViewPackagesBookings", component: ViewPackagesBookings },

  //PackagesTicketRasinglist
  { path: "/PackagesTicketRasinglist", component: PackagesTicketRasinglist },

  //home service slot MAKING
  { path: "/AddHomeServiceHoliday", component: AddHomeServiceHoliday },
  { path: "/EditHomeServiceHoliday", component: EditHomeServiceHoliday },

  //Doctor slot MAKING
  { path: "/AddDoctorHolidayList", component: AddDoctorHolidayList },
  { path: "/EditDoctorHolidayList", component: EditDoctorHolidayList },

  { path: "/InvestigationsTickets", component: InvestigationsTickets },

  { path: "/ConsultationTickets", component: ConsultationTickets },

  { path: "/HomeServiceTickets", component: HomeServiceTickets },

  {
    path: "/AddInvestigationHolidaySlot",
    component: AddInvestigationHolidaySlot,
  },
  {
    path: "/EditInvestigationHolidaySlot",
    component: EditInvestigationHolidaySlot,
  },

  { path: "/AddRoles", component: AddRoles },

  { path: "/Packages", component: Packages },

  { path: "/EditRoles", component: EditRoles },

  { path: "/Payments", component: Payments },

  { path: "/EmergencyBookings", component: EmergencyBookings },

  { path: "/Actinstant", component: Actinstant },

  { path: "/EditDoctor", component: EditDoctor },

  { path: "/HomeServiceRequestlist", component: HomeServiceRequestlist },

  { path: "/Investigationrequestlist", component: Investigationrequestlist },

  { path: "/TestRequestlist", component: TestRequestlist },

  { path: "/EditHomeServiceSlotMaking", component: EditHomeServiceSlotMaking },

  { path: "/EditTimeSlot", component: EditTimeSlot },

  { path: "/TicketsRasingList", component: TicketsRasingList },

  { path: "/ContactUs", component: ContactUs },

  { path: "/HomeServiceRequest", component: HomeServiceRequest },

  { path: "/AddHomeService", component: AddHomeService },

  { path: "/HomeServiceList", component: HomeServiceList },

  { path: "/Investigationrequest", component: Investigationrequest },

  { path: "/TestRequest", component: TestRequest },

  { path: "/Investigationlist", component: Investigationlist },

  { path: "/AddInvestigationlist", component: AddInvestigationlist },

  { path: "/AddTestsMaking", component: AddTestsMaking },

  { path: "/TestsMaking", component: TestsMaking },

  { path: "/EditTestsMaking", component: EditTestsMaking },

  { path: "/HomeService", component: HomeService },

  { path: "/DoctorView", component: DoctorView },

  { path: "/Consultation", component: Consultation },

  { path: "/ProfileUpdateRequest", component: ProfileUpdateRequest },

  { path: "/AddBookingTest", component: AddBookingTest },

  { path: "/Tests", component: Tests },

  { path: "/ViewUser", component: ViewUser },

  {
    path: "/ViewAcceptingInvestigation",
    component: ViewAcceptingInvestigation,
  },

  { path: "/ViewHomeSlotService", component: ViewHomeSlotService },

  { path: "/TestsView", component: TestsView },

  { path: "/AddB2B", component: AddB2B },
  { path: "/B2BList", component: B2BList },

  { path: "/AddHomeServiceSlotMaking", component: AddHomeServiceSlotMaking },

  { path: "/HomeServiceSlotList", component: HomeServiceSlotList },

  { path: "/TestList", component: TestList },

  { path: "/AcceptingInvestigation", component: AcceptingInvestigation },

  { path: "/HospitalProfile", component: HospitalProfile },

  { path: "/AddDoctor", component: AddDoctor },
  { path: "/DoctorProfile", component: DoctorProfile },
  { path: "/DoctorSchedule", component: DoctorSchedule },

  { path: "/AddTimeslot", component: AddTimeslot },
  { path: "/Timeslotlist", component: Timeslotlist },

  { path: "/AddTest", component: AddTest },

  //USERS
  { path: "/Adduser", component: Adduser },
  { path: "/Users", component: Users },
  { path: "/Edituser", component: Edituser },

  //Staff
  { path: "/Departments", component: Departments },
  { path: "/RolesPremissions", component: RolesPremissions },
  { path: "/Staff", component: Staff },

  //SETTINGS
  { path: "/Manual", component: Faqs },
  { path: "/Termsconditions", component: Terms },
  { path: "/PrivicyPolicy", component: PrivicyPolicy },
  { path: "/About", component: About },
  { path: "/RefundPolicy", component: RefundPolicy },

  //Support
  { path: "/Support", component: Support },

  //Chat
  { path: "/Chat", component: Chat },

  //Reports
  { path: "/HomeServiceReport", component: HomeServiceReport },
  { path: "/DoctorConsultationReport", component: DoctorConsultationReport },
  {
    path: "/AcceptingInvestigationsReport",
    component: AcceptingInvestigationsReport,
  },
  { path: "/BookingsTestsReport", component: BookingsTestsReport },

  { path: "/PackagesReport", component: PackagesReport },
  { path: "/ActinstantReport", component: ActinstantReport },
  { path: "/EmergencyReport", component: EmergencyReport },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/deleteaccount", component: DeleteAccount },
  { path: "/login", component: Login },
  { path: "/ForgetPassword", component: ForgetPassword },
  { path: "/Resetpsw", component: Resetpsw },
  { path: "/Compareotp", component: Compareotp },
  { path: "/register", component: Register },
  { path: "/Sample", component: Sample },
]

export { publicRoutes, authProtectedRoutes }
