const Url = "https://api.actinplus.com/"

export const URLS = {
  Base: Url,

  //GetSettings
  GetSetting: Url + "v1/actinapi/vendor/setting/getSetting",
  Changepass: Url + "v1/actinapi/vendor/auth/chagepassword",
  forget: Url + "v1/actinapi/vendor/auth/sendOtpemail",
  OTP: Url + "v1/actinapi/vendor/auth/compareotp",
  Resetpass: Url + "v1/actinapi/vendor/auth/resetpassword",

  //Manual
  GetManual: Url + "v1/actinapi/vendor/manual/getall",

  //Profile
  getProfile: Url + "v1/actinapi/vendor/auth/getProfile",

  //Contact Us
  GetContactUs: Url + "v1/actinapi/vendor/contactus/get",

  //Department
  AddDepartment: Url + "v1/actinapi/vendor/hospitaldepartment/add",
  GetDepartment: Url + "v1/actinapi/vendor/hospitaldepartment/getAll",
  GetDepartmentSearch:
    Url + "v1/actinapi/vendor/hospitaldepartment/getAll?searchQuery=",
  UpdateDepartment: Url + "v1/actinapi/vendor/hospitaldepartment/edit",
  InActiveDepartment: Url + "v1/actinapi/vendor/hospitaldepartment/delete",

  //Role
  AddRole: Url + "v1/actinapi/vendor/role/addrole",
  GetRole: Url + "v1/actinapi/vendor/role/getAll",
  GetOneRole: Url + "v1/actinapi/vendor/role/getrole",
  EditRole: Url + "v1/actinapi/vendor/role/editrole",
  DeleteRole: Url + "v1/actinapi/vendor/role/delete/",

  //Staff
  AddStaff: Url + "v1/actinapi/vendor/staff/addstaff",
  GetStaff: Url + "v1/actinapi/vendor/staff/getAll",
  GetStaffSearch: Url + "v1/actinapi/vendor/staff/getAll?searchQuery=",
  EditStaff: Url + "v1/actinapi/vendor/staff/editstaff/",
  DeleteStaff: Url + "v1/actinapi/vendor/staff/delete/",
  GetIdRole: Url + "v1/actinapi/vendor/staff/getdropdown",
  GetIdStaff: Url + "v1/actinapi/vendor/staff/getstaff",

  //Staff
  AddRequestprofile: Url + "v1/actinapi/vendor/requestprofile/add",
  GetRequestprofile: Url + "v1/actinapi/vendor/requestprofile/getall",
  GetRequestprofileSearch:
    Url + "v1/actinapi/vendor/requestforprofile/getAll?searchQuery=",

  //-add testrequest
  AddTestRequest: Url + "v1/actinapi/vendor/testrequest/add",
  GetRequest: Url + "v1/actinapi/vendor/testrequest/getAll",
  GetRequestSearch: Url + "v1/actinapi/vendor/testrequest/getAll?searchQuery=",
  UpdatePromotions: Url + "v1/actinapi/vendor/auth/updatepromo",

  //-getAll hometest
  getAllhometest: Url + "v1/actinapi/vendor/hometestprice/get",
  getAllhometestSearch:
    Url + "v1/actinapi/vendor/hometestprice/get?searchQuery=",
  addAllhometest: Url + "v1/actinapi/vendor/hometestprice/addtestprice",
  getaddAllhometest: Url + "v1/actinapi/vendor/hometestprice/gethospitaltests",
  getaddAllhometestSearch:
    Url + "v1/actinapi/vendor/hometestprice/gethospitaltests?searchQuery=",

  //-Packege Request
  addPackegeRequest: Url + "v1/actinapi/vendor/packagerequest/add",
  getPackegeRequest: Url + "v1/actinapi/vendor/packagerequest/getAll",
  getPackegeRequestSearch:
    Url + "v1/actinapi/vendor/packagerequest/getAll?searchQuery=",
  UpdatePackegeRequest: Url + "v1/actinapi/vendor/packagerequest/editpackage/",
  getonePackegeRequest: Url + "v1/actinapi/vendor/packagerequest/getdetails",

  //-getAll tests bookings
  getAllbookings: Url + "v1/actinapi/vendor/booking/getAll",
  getAllbookingsSearch: Url + "v1/actinapi/vendor/booking/getAll?searchQuery=",
  AcceptBookingRequest: Url + "v1/actinapi/vendor/hsptlbooking/add",
  UploadBookingReports: Url + "v1/actinapi/vendor/bookingtestreport/add",
  getBookingRequest: Url + "v1/actinapi/vendor/packagerequest/getAll",

  TestBookingDeny: Url + "v1/actinapi/vendor/hsptlbooking/rejectsample",

  PackagesBookingGet: Url + "v1/actinapi/vendor/bookingpackage/getall",
  PackagesBookingGetsearch:
    Url + "v1/actinapi/vendor/bookingpackage/getall?searchQuery=",
  PackagesBookingGetOne:
    Url + "v1/actinapi/vendor/bookingpackage/getbookingdtls",
  UpdatePackagesBookingGetOne:
    Url + "v1/actinapi/vendor/bookingpackage/acceptbooking",

  getBookingOneView:
    Url + "v1/actinapi/vendor/bookingtestreport/getbookingdtls",

  //-getAllInvestigation List
  getAllInvestigations:
    Url + "v1/actinapi/vendor/investigation/getallactininvest",
  getAllInvestigationsSearch:
    Url + "v1/actinapi/vendor/investigation/getallactininvest?searchQuery=",
  addAllInvestigations:
    Url + "v1/actinapi/vendor/investigation/addinvestpriceinhoc",
  getaddAllInvestigations:
    Url + "v1/actinapi/vendor/investigation/getallhocinvest",
  getaddAllInvestigationssearch:
    Url + "v1/actinapi/vendor/investigation/getallhocinvest?searchQuery=",

  //-NEW INVESTIGATION REQUEST
  AddInvestigatinsRequest:
    Url + "v1/actinapi/vendor/investigation/addinvestigationrequest",
  GetAllInvestigatinsRequest:
    Url + "v1/actinapi/vendor/investigation/getpendinginvestigationrequest",
  GetAllInvestigatinsRequestSearch:
    Url +
    "v1/actinapi/vendor/investigation/getpendinginvestigationrequest?searchQuery=",

  pricerequestInvestigation:
    Url + "v1/actinapi/vendor/investigationticket/addinvestigationticket",

  GetAllpricerequestInvestigation:
    Url + "v1/actinapi/vendor/investigationticket/getallinvestigationtickets",

  GetAllpricerequestInvestigationSearch:
    Url +
    "v1/actinapi/vendor/investigationticket/getallinvestigationtickets?searchQuery=",

  //-test ticket raising
  addtestticketraising: Url + "v1/actinapi/vendor/testticketraising/add",
  Gettestticketraising: Url + "v1/actinapi/vendor/testticketraising/getAll",
  GettestticketraisingSearch:
    Url + "v1/actinapi/vendor/testticketraising/getAll?searchQuery=",

  //-AddDoctor
  AddDoctor: Url + "v1/actinapi/vendor/doctor/adddoctor",
  GetAllDoctor: Url + "v1/actinapi/vendor/doctor/getAll",
  GetAllDoctorSearch: Url + "v1/actinapi/vendor/doctor/getAll?searchQuery=",
  UpdateDoctor: Url + "v1/actinapi/vendor/doctor/editdctr",
  DeleteDoctor: Url + "v1/actinapi/vendor/doctor/deletedctr",
  ViewDoctor: Url + "v1/actinapi/vendor/doctor/get",

  GetAllServiceProviders: Url + "v1/actinapi/vendor/doctor/getallsrvprvs",
  GetAllServiceProvidersSearch:
    Url + "v1/actinapi/vendor/doctor/getallsrvprvs?searchQuery=",

  UpdateDoctorprice: Url + "v1/actinapi/vendor/doctorticket/adddoctorticket",
  GetDoctorTicketraise:
    Url + "v1/actinapi/vendor/doctorticket/getalldoctortickets",

  GetDoctorTicketraiseSearch:
    Url + "v1/actinapi/vendor/doctorticket/getalldoctortickets?searchQuery=",

  //-HomeServices
  GetadminHomeServiceslist:
    Url + "v1/actinapi/vendor/homeservice/getallhomeservices",
  AddHomeServiceslist: Url + "v1/actinapi/vendor/homeservice/addhmservice",
  GetallHomeServiceslist: Url + "v1/actinapi/vendor/homeservice/getAll",
  GetallHomeServiceslistSearch:
    Url + "v1/actinapi/vendor/homeservice/getAll?searchQuery=",

  // UpdateDoctor: Url + "v1/actinapi/vendor/doctor/editdctr",
  // DeleteDoctor: Url + "v1/actinapi/vendor/doctor/deletedctr",
  // ViewDoctor: Url + "v1/actinapi/vendor/doctor/get",

  AddHomeRequest: Url + "v1/actinapi/vendor/homeservice/request",
  GetallHomeRequest:
    Url + "v1/actinapi/vendor/homeservice/getAllhmserviceRequest",

  GetallHomeRequestSearch:
    Url + "v1/actinapi/vendor/homeservice/getAllhmserviceRequest?searchQuery=",

  AddHomePriceRequest: Url + "v1/actinapi/vendor/homeserrvies/ticket/add",
  GetallHomePriceRequest: Url + "v1/actinapi/vendor/homeserrvies/ticket/getall",

  GetallHomePriceRequestSearch:
    Url + "v1/actinapi/vendor/homeserrvies/ticket/getall?searchQuery=",

  //investion Slot Making
  AddInvestigationTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/create",
  GetallInvestigationTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/getAll",

  GetallInvestigationTimeSlotsSearch:
    Url + "v1/actinapi/vendor/investigation/slots/getAll?searchQuery=",

  UpdateInvestigationTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/update",
  //getinvestiondetails
  getinvestiononedetails:
    Url + "v1/actinapi/vendor/investigation/slots/getinvestigation",
  getInvestigationTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/single",
  DeleteInvestigationTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/delete",
  AddHolidayInvestigationTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/createholidayslots",
  GetHolidayInvestigationTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/getholidays",
  DeleteInvestigationHolidayTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/deleteholiday",
  GetOneInvestigationHolidayTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/getSingleHolidaySlots",
  UPDATEOneInvestigationHolidayTimeSlots:
    Url + "v1/actinapi/vendor/investigation/slots/updateholidayslot",

  //Services Slot Making
  AddServicesSlotMaking: Url + "v1/actinapi/vendor/homeservice/slots/create",
  GetbyServicesprice:
    Url + "v1/actinapi/vendor/homeservice/slots/gethomeservice",
  GetOneHomeServicesSlot: Url + "v1/actinapi/vendor/homeservice/slots/single",
  GetallHomeserviceslots: Url + "v1/actinapi/vendor/homeservice/slots/getAll",
  UpdateHomeserviceslots: Url + "v1/actinapi/vendor/homeservice/slots/update",
  DeleteHomeServicesTimeSlots:
    Url + "v1/actinapi/vendor/homeservice/slots/delete",
  AddHolidayHomeServiceTimeSlots:
    Url + "v1/actinapi/vendor/homeservice/slots/createholidayslots",
  GetHolidayHomeServiceTimeSlots:
    Url + "v1/actinapi/vendor/homeservice/slots/getholidays",
  DeleteHomeServiceHolidayTimeSlots:
    Url + "v1/actinapi/vendor/homeservice/slots/deleteholiday",
  GetOneHomeServiceHolidayTimeSlots:
    Url + "v1/actinapi/vendor/homeservice/slots/getSingleHolidaySlots",
  UPDATEOneHomeServiceHolidayTimeSlots:
    Url + "v1/actinapi/vendor/homeservice/slots/updateholidayslot",

  //Doctor Holiday List
  AddDoctorSlotMaking: Url + "v1/actinapi/vendor/doctor/slots/create",
  GetbyDoctorsprice: Url + "v1/actinapi/vendor/doctor/slots/getdoctor",
  GetOneDoctorSlot: Url + "v1/actinapi/vendor/doctor/slots/single",
  GetallDoctorlots: Url + "v1/actinapi/vendor/doctor/slots/getAll",
  UpdateDoctorlots: Url + "v1/actinapi/vendor/doctor/slots/update",
  DeleteDoctorTimeSlots: Url + "v1/actinapi/vendor/doctor/slots/delete",
  AddHolidayDoctorTimeSlots:
    Url + "v1/actinapi/vendor/doctor/slots/createholidayslots",
  GetHolidayDoctorTimeSlots:
    Url + "v1/actinapi/vendor/doctor/slots/getholidays",
  DeleteDoctorHolidayTimeSlots:
    Url + "v1/actinapi/vendor/doctor/slots/deleteholiday",
  GetOneDoctorHolidayTimeSlots:
    Url + "v1/actinapi/vendor/doctor/slots/getSingleHolidaySlots",
  UPDATEOneDoctorHolidayTimeSlots:
    Url + "v1/actinapi/vendor/doctor/slots/updateholidayslot",

  //investigations Bookings
  getAllInvestigationBookings:
    Url + "v1/actinapi/vendor/booking/investigations/getAll",
  getAllInvestigationBookingsSearch:
    Url + "v1/actinapi/vendor/booking/investigations/getAll?searchQuery=",

  AcceptInvestigationsBookingRequest:
    Url + "v1/actinapi/vendor/booking/investigations/addhsptlbooking",
  UploadinvestigationsBookingReports:
    Url + "v1/actinapi/vendor/bookingtestreport/add",
  getinvestigationsBookingRequest:
    Url + "v1/actinapi/vendor/packagerequest/getAll",
  getinvestigationsBookingOneView:
    Url + "v1/actinapi/vendor/booking/investigations/getBookingDetails",

  //Doctor Constultation
  getAllDoctorConstultationBookings:
    Url + "v1/actinapi/vendor/bookings/consultancy/getall",
  getAllDoctorConstultationBookingsSearch:
    Url + "v1/actinapi/vendor/bookings/consultancy/getall?searchQuery=",
  AcceptDoctorConstultationBookingRequest:
    Url + "v1/actinapi/vendor/bookings/consultancy/addhsptlbooking",
  UploadDoctorConstultationBookingReports:
    Url + "v1/actinapi/vendor/bookings/consultancy/prescriptionupload",
  getDoctorConstultationBookingRequest:
    Url + "v1/actinapi/vendor/packagerequest/getAll",
  getiDoctorConstultationBookingOneView:
    Url + "v1/actinapi/vendor/bookings/consultancy/bookingDetails",

  //HomeService
  getAllHomeServiceBookings:
    Url + "v1/actinapi/vendor/bookings/homeservices/getall",

  getAllHomeServiceBookingsSearch:
    Url + "v1/actinapi/vendor/bookings/homeservices/getall?searchQuery=",

  AcceptHomeServiceBookingRequest:
    Url + "v1/actinapi/vendor/bookings/homeservices/accpetstatus",
  UploadHomeServiceBookingReports:
    Url + "v1/actinapi/vendor/bookingtestreport/add",
  getHomeServiceBookingRequest:
    Url + "v1/actinapi/vendor/packagerequest/getAll",
  getiHomeServiceBookingOneView:
    Url + "v1/actinapi/vendor/bookings/homeservices/bookingDetails",
  gethomeprovider: Url + "v1/actinapi/vendor/bookings/homeservices/getDoctors",

  //GetDashboard
  GetDashboard: Url + "v1/actinapi/admin/dashboard/hospital",

  //GetAllReports
  GetAllBookingTestReports: Url + "v1/actinapi/vendor/reports/test",
  GetAllBookingInvestigationReports:
    Url + "v1/actinapi/vendor/reports/investigation",
  GetAllBookingConstultationReports:
    Url + "v1/actinapi/vendor/reports/consultation",
  GetAllBookingHomeServiceReports:
    Url + "v1/actinapi/vendor/reports/homeservice",

  GetAllBookingEmergencyReports:
    Url + "v1/actinapi/vendor/reports/emergencytest",
  GetAllBookingActinstantReports:
    Url + "v1/actinapi/vendor/reports/actinstanttest",
  GetAllPackagesReports: Url + "v1/actinapi/vendor/reports/testpackage",

  //Ticket List
  GetHCUTicket: Url + "v1/actinapi/vendor/tickets/getall",
  AddHCUTicket: Url + "v1/actinapi/vendor/tickets/create",

  //Packages Edit Request
  GetPackageTicketRasing:
    Url + "v1/actinapi/vendor/packageticketraising/getall",
  GetPackageTicketRasingSearch:
    Url + "v1/actinapi/vendor/packageticketraising/getall?searchQuery=",
  AddPackageTicketRasing: Url + "v1/actinapi/vendor/packageticketraising/add",

  //PAYMENT GENERATE
  AddPAYMENTGENERATE:
    Url + "v1/actinapi/vendor/hospitalmonthlypay/addpaydetails",
  GetPAYMENTGENERATE: Url + "v1/actinapi/vendor/hospitalmonthlypay/getall",
  GETONEPAYMENTGENERATE:
    Url + "v1/actinapi/vendor/hospitalmonthlypay/getdetails",
  UpdateONEPAYMENT:
    Url + "v1/actinapi/vendor/hospitalmonthlypay/updatereceipt/",

  GetByspeciality: Url + "v1/actinapi/vendor/doctor/getallspecs",
}
