import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"

const Roles = () => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const [ras, setras] = useState({
    Dashview: false,

    hospitalListView: false,

    EmployeeManagmentView: false,

    departementView: false,
    departementAdd: false,
    departementeEdit: false,
    departementBlock: false,

    rolesAndPermissionView: false,
    rolesAndPermissionAdd: false,
    rolesAndPermissionEdit: false,

    staffView: false,
    staffAdd: false,
    staffEdit: false,
    staffBlock: false,

    TestsAtHomeView: false,

    homeTestListView: false,
    homeTestListAdd: false,
    homeTestListEdit: false,

    newTestsRequestView: false,
    newTestsRequestAdd: false,

    packagesRequestView: false,
    packagesRequestAdd: false,

    homeBookingListView: false,
    homeBookingListEdit: false,

    emergencyBookingListView: false,
    emergencyBookingListEdit: false,

    actInstantBookingListView: false,
    actInstantBookingListEdit: false,

    InvestigationsAtHCUView: false,

    investigationsListView: false,
    investigationsListAdd: false,
    investigationsListEdit: false,

    investigationsRequestsView: false,
    investigationsRequestsAdd: false,
    investigationsRequestsEdit: false,

    InvestigationSlotMakingView: false,
    InvestigationSlotMakingAdd: false,
    InvestigationSlotMakingEdit: false,

    investigationAtHcuView: false,
    investigationAtHcuEdit: false,

    DoctorConsultationsView: false,

    DoctorConsultationAdd: false,
    DoctorConsultationView: false,
    DoctorConsultationEdit: false,

    DoctorConsultationSlotMakingAdd: false,
    DoctorConsultationSlotMakingView: false,
    DoctorConsultationSlotMakingEdit: false,

    consultationView: false,
    consultationEdit: false,

    HomeServiceView: false,

    homeServiceListView: false,
    homeServiceListAdd: false,
    homeServiceListEdit: false,

    newHomeServiceRequestView: false,
    newHomeServiceRequestEdit: false,
    newHomeServiceRequestAdd: false,

    HomeServiceSlotMakingAdd: false,
    HomeServiceSlotMakingView: false,
    HomeServiceSlotMakingEdit: false,

    homeServiceView: false,
    homeServiceEdit: false,

    PaymentsReportsView: false,

    homeTestReportView: false,
    investigationAtHcuReportView: false,
    consultationReportView: false,
    homeServiceReportView: false,

    paymentsView: false,

    TechinicalSupportAdd: false,
    TechinicalSupportView: false,

    ticketRaisingListView: false,

    hospitalProfileRequestView: false,
    hospitalProfileRequestAdd: false,

    manualView: false,

    cgsCornerView: false,

    SettingsView: false,

    settingsView: false,

    newHomeServiceRequestAdd: false,
  })

  const handleChange1s = e => {
    const myUser = { ...ras }
    myUser[e.target.name] = e.target.checked
    setras(myUser)
  }

  const [form, setform] = useState([])

  const handleSubmit = e => {
    e.preventDefault()
    Addrole()
  }

  const check = {
    Dashview: ras.Dashview,

    hospitalListView: ras.hospitalListView,

    EmployeeManagmentView: ras.EmployeeManagmentView,

    departementView: ras.departementView,
    departementAdd: ras.departementAdd,
    departementeEdit: ras.departementeEdit,
    departementBlock: ras.departementBlock,

    rolesAndPermissionView: ras.rolesAndPermissionView,
    rolesAndPermissionAdd: ras.rolesAndPermissionAdd,
    rolesAndPermissionEdit: ras.rolesAndPermissionEdit,

    InvestigationSlotMakingEdit: ras.InvestigationSlotMakingEdit,

    staffView: ras.staffView,
    staffAdd: ras.staffAdd,
    staffEdit: ras.staffEdit,
    staffBlock: ras.staffBlock,

    TestsAtHomeView: ras.TestsAtHomeView,

    homeTestListView: ras.homeTestListView,
    homeTestListAdd: ras.homeTestListAdd,
    homeTestListEdit: ras.homeTestListEdit,

    newTestsRequestView: ras.newTestsRequestView,
    newTestsRequestAdd: ras.newTestsRequestAdd,

    packagesRequestView: ras.packagesRequestView,
    packagesRequestAdd: ras.packagesRequestAdd,

    homeBookingListView: ras.homeBookingListView,
    homeBookingListEdit: ras.homeBookingListEdit,

    emergencyBookingListView: ras.emergencyBookingListView,
    emergencyBookingListEdit: ras.emergencyBookingListEdit,

    actInstantBookingListView: ras.actInstantBookingListView,
    actInstantBookingListEdit: ras.actInstantBookingListEdit,

    InvestigationsAtHCUView: ras.InvestigationsAtHCUView,

    investigationsRequestsView: ras.investigationsRequestsView,
    investigationsRequestsAdd: ras.investigationsRequestsAdd,
    investigationsListEdit: ras.investigationsListEdit,

    investigationsListView: ras.investigationsListView,
    investigationsListAdd: ras.investigationsListAdd,
    investigationsRequestsEdit: ras.investigationsRequestsEdit,

    InvestigationSlotMakingView: ras.InvestigationSlotMakingView,
    InvestigationSlotMakingAdd: ras.InvestigationSlotMakingAdd,

    investigationAtHcuView: ras.investigationAtHcuView,
    investigationAtHcuEdit: ras.investigationAtHcuEdit,

    DoctorConsultationsView: ras.DoctorConsultationsView,

    DoctorConsultationAdd: ras.DoctorConsultationAdd,
    DoctorConsultationView: ras.DoctorConsultationView,
    DoctorConsultationEdit: ras.DoctorConsultationEdit,

    DoctorConsultationSlotMakingAdd: ras.DoctorConsultationSlotMakingAdd,
    DoctorConsultationSlotMakingView: ras.DoctorConsultationSlotMakingView,
    DoctorConsultationSlotMakingEdit: ras.DoctorConsultationSlotMakingEdit,

    consultationView: ras.consultationView,
    consultationEdit: ras.consultationEdit,

    HomeServiceView: ras.HomeServiceView,

    newHomeServiceRequestAdd: ras.newHomeServiceRequestAdd,

    homeServiceListView: ras.homeServiceListView,
    homeServiceListAdd: ras.homeServiceListAdd,
    homeServiceListEdit: ras.homeServiceListEdit,

    newHomeServiceRequestView: ras.newHomeServiceRequestView,
    newHomeServiceRequestEdit: ras.newHomeServiceRequestEdit,

    HomeServiceSlotMakingAdd: ras.HomeServiceSlotMakingAdd,
    HomeServiceSlotMakingView: ras.HomeServiceSlotMakingView,
    HomeServiceSlotMakingEdit: ras.HomeServiceSlotMakingEdit,

    homeServiceView: ras.homeServiceView,
    homeServiceEdit: ras.homeServiceEdit,

    PaymentsReportsView: ras.PaymentsReportsView,

    homeTestReportView: ras.homeTestReportView,
    investigationAtHcuReportView: ras.investigationAtHcuReportView,
    consultationReportView: ras.consultationReportView,
    homeServiceReportView: ras.homeServiceReportView,

    paymentsView: ras.paymentsView,

    TechinicalSupportAdd: ras.TechinicalSupportAdd,
    TechinicalSupportView: ras.TechinicalSupportView,

    ticketRaisingListView: ras.ticketRaisingListView,

    hospitalProfileRequestView: ras.hospitalProfileRequestView,
    hospitalProfileRequestAdd: ras.hospitalProfileRequestAdd,

    manualView: ras.manualView,

    cgsCornerView: ras.cgsCornerView,

    SettingsView: ras.SettingsView,

    settingsView: ras.settingsView,
  }

  const Addrole = () => {
    const token = datas

    const data = {
      hospitalId: datasd,
      roleName: form.roleName,
      rolesAndPermission: check,
    }

    axios
      .post(URLS.AddRole, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/RolesPremissions")
            sessionStorage.setItem("tost", "Roles has been Added Successfully")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  function handleChange(e) {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const history = useHistory()

  const [dep, setdep] = useState([])

  useEffect(() => {
    Getalldep()
  }, [])

  const Getalldep = () => {
    var token = datas
    axios
      .post(
        URLS.GetDepartment,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setdep(res.data.hsptldpts)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Add Roles" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white mt-2">
                  <CardTitle>Role & Permissions</CardTitle>
                </CardHeader>

                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <Row>
                      {/* <Col md={4}>
                        <Label> Department</Label>
                        <span className="text-danger">*</span>
                        <select
                          value={form.hospitaldepartmentId}
                          name="hospitaldepartmentId"
                          required
                          onChange={e => {
                            handleChange(e)
                          }}
                          className="form-select"
                        >
                          <option value="">Select</option>
                          {dep.map((data, key) => {
                            return (
                              <option key={key} value={data._id}>
                                {data.departmentName}
                              </option>
                            )
                          })}
                        </select>
                      </Col> */}
                      <Col md={4}>
                        <Label for="basicpill-firstname-input1">
                          Role <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Role  Name"
                          required
                          pattern="^[a-zA-Z ]*$"
                          value={form.roleName}
                          name="roleName"
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className=" mt-3">
                      <h5 className="mt-4 mb-3">Dashboard:</h5>
                      <Col md={2}>
                        <p className="">Dashboard: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="Dashview"
                            defaultChecked={ras.Dashview}
                            value={ras.Dashview}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="read">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">All Hospital:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className=""> Hospital View: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalListView"
                            defaultChecked={ras.hospitalListView}
                            value={ras.hospitalListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Employee Managment:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Employee Managment View: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="EmployeeManagmentView"
                            defaultChecked={ras.EmployeeManagmentView}
                            value={ras.EmployeeManagmentView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Department : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementView"
                            defaultChecked={ras.departementView}
                            value={ras.departementView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementAdd"
                            defaultChecked={ras.departementAdd}
                            value={ras.departementAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementeEdit"
                            defaultChecked={ras.departementeEdit}
                            value={ras.departementeEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementBlock"
                            defaultChecked={ras.departementBlock}
                            value={ras.departementBlock}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Block
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Roles & Premissions : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionView"
                            defaultChecked={ras.rolesAndPermissionView}
                            value={ras.rolesAndPermissionView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionAdd"
                            defaultChecked={ras.rolesAndPermissionAdd}
                            value={ras.rolesAndPermissionAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionEdit"
                            defaultChecked={ras.rolesAndPermissionEdit}
                            value={ras.rolesAndPermissionEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Staff : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffView"
                            defaultChecked={ras.staffView}
                            value={ras.staffView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffAdd"
                            defaultChecked={ras.staffAdd}
                            value={ras.staffAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffEdit"
                            defaultChecked={ras.staffEdit}
                            value={ras.staffEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffBlock"
                            defaultChecked={ras.staffBlock}
                            value={ras.staffBlock}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Block
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Tests At Home :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Tests At Home View : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="TestsAtHomeView"
                            defaultChecked={ras.TestsAtHomeView}
                            value={ras.TestsAtHomeView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Home Tests List : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeTestListView"
                            defaultChecked={ras.homeTestListView}
                            value={ras.homeTestListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeTestListAdd"
                            defaultChecked={ras.homeTestListAdd}
                            value={ras.homeTestListAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeTestListEdit"
                            defaultChecked={ras.homeTestListEdit}
                            value={ras.homeTestListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">New Tests Request : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newTestsRequestView"
                            defaultChecked={ras.newTestsRequestView}
                            value={ras.newTestsRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newTestsRequestAdd"
                            defaultChecked={ras.newTestsRequestAdd}
                            value={ras.newTestsRequestAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Packages request : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="packagesRequestView"
                            defaultChecked={ras.packagesRequestView}
                            value={ras.packagesRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="packagesRequestAdd"
                            defaultChecked={ras.packagesRequestAdd}
                            value={ras.packagesRequestAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Add / Edit
                          </Label>
                        </div>
                      </Col>
                      <Row className="mt-2">
                        <Col md={2}>
                          <p className="">Home Booking List : </p>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={2}>
                          <div className="form-check me-3 me-lg-5">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="homeBookingListView"
                              defaultChecked={ras.homeBookingListView}
                              value={ras.homeBookingListView}
                              onClick={e => {
                                handleChange1s(e)
                              }}
                              id="read"
                            />
                            <Label
                              onClick={e => {
                                handleChange1s(e)
                              }}
                              className="form-check-label"
                              for="empView"
                            >
                              View
                            </Label>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="form-check me-3 me-lg-5">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="homeBookingListEdit"
                              defaultChecked={ras.homeBookingListEdit}
                              value={ras.homeBookingListEdit}
                              onClick={e => {
                                handleChange1s(e)
                              }}
                              id="read"
                            />
                            <Label className="form-check-label" for="empEdit">
                              Edit
                            </Label>
                          </div>
                        </Col>
                        <Col md={1}></Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={2}>
                          <p className="">Emergency Booking List: </p>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={2}>
                          <div className="form-check me-3 me-lg-5">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="emergencyBookingListView"
                              defaultChecked={ras.emergencyBookingListView}
                              value={ras.emergencyBookingListView}
                              onClick={e => {
                                handleChange1s(e)
                              }}
                              id="read"
                            />
                            <Label
                              onClick={e => {
                                handleChange1s(e)
                              }}
                              className="form-check-label"
                              for="empView"
                            >
                              View
                            </Label>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="form-check me-3 me-lg-5">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="emergencyBookingListEdit"
                              defaultChecked={ras.emergencyBookingListEdit}
                              value={ras.emergencyBookingListEdit}
                              onClick={e => {
                                handleChange1s(e)
                              }}
                              id="read"
                            />
                            <Label className="form-check-label" for="empEdit">
                              Edit
                            </Label>
                          </div>
                        </Col>
                        <Col md={1}></Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={2}>
                          <p className="">ACT-INstant Booking List </p>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={2}>
                          <div className="form-check me-3 me-lg-5">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="actInstantBookingListView"
                              defaultChecked={ras.actInstantBookingListView}
                              value={ras.actInstantBookingListView}
                              onClick={e => {
                                handleChange1s(e)
                              }}
                              id="read"
                            />
                            <Label
                              onClick={e => {
                                handleChange1s(e)
                              }}
                              className="form-check-label"
                              for="empView"
                            >
                              View
                            </Label>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="form-check me-3 me-lg-5">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              name="actInstantBookingListEdit"
                              defaultChecked={ras.actInstantBookingListEdit}
                              value={ras.actInstantBookingListEdit}
                              onClick={e => {
                                handleChange1s(e)
                              }}
                              id="read"
                            />
                            <Label className="form-check-label" for="empEdit">
                              Edit
                            </Label>
                          </div>
                        </Col>
                        <Col md={1}></Col>
                      </Row>
                    </Row>
                    <h5 className="mt-3 mb-3">Investigations At HCU :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Investigations At HCU View: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="InvestigationsAtHCUView"
                            defaultChecked={ras.InvestigationsAtHCUView}
                            value={ras.InvestigationsAtHCUView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Investigations List : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsListView"
                            defaultChecked={ras.investigationsListView}
                            value={ras.investigationsListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsListAdd"
                            defaultChecked={ras.investigationsListAdd}
                            value={ras.investigationsListAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsListEdit"
                            defaultChecked={ras.investigationsListEdit}
                            value={ras.investigationsListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Investigations Request : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsRequestsView"
                            defaultChecked={ras.investigationsRequestsView}
                            value={ras.investigationsRequestsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsRequestsAdd"
                            defaultChecked={ras.investigationsRequestsAdd}
                            value={ras.investigationsRequestsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsRequestsEdit"
                            defaultChecked={ras.investigationsRequestsEdit}
                            value={ras.investigationsRequestsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        <p className=""> Investigations Slot Making : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="InvestigationSlotMakingView"
                            defaultChecked={ras.InvestigationSlotMakingView}
                            value={ras.InvestigationSlotMakingView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="InvestigationSlotMakingAdd"
                            defaultChecked={ras.InvestigationSlotMakingAdd}
                            value={ras.InvestigationSlotMakingAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="InvestigationSlotMakingEdit"
                            defaultChecked={ras.InvestigationSlotMakingEdit}
                            value={ras.InvestigationSlotMakingEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Investigations at HCU : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationAtHcuView"
                            defaultChecked={ras.investigationAtHcuView}
                            value={ras.investigationAtHcuView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationAtHcuEdit"
                            defaultChecked={ras.investigationAtHcuEdit}
                            value={ras.investigationAtHcuEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Doctor Consultation :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Doctor Consultation View : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DoctorConsultationsView"
                            defaultChecked={ras.DoctorConsultationsView}
                            value={ras.DoctorConsultationsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Doctor Consultation : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DoctorConsultationAdd"
                            defaultChecked={ras.DoctorConsultationAdd}
                            value={ras.DoctorConsultationAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DoctorConsultationView"
                            defaultChecked={ras.DoctorConsultationView}
                            value={ras.DoctorConsultationView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DoctorConsultationEdit"
                            defaultChecked={ras.DoctorConsultationEdit}
                            value={ras.DoctorConsultationEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Doctor Slot Making : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DoctorConsultationSlotMakingAdd"
                            defaultChecked={ras.DoctorConsultationSlotMakingAdd}
                            value={ras.DoctorConsultationSlotMakingAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DoctorConsultationSlotMakingView"
                            defaultChecked={
                              ras.DoctorConsultationSlotMakingView
                            }
                            value={ras.DoctorConsultationSlotMakingView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DoctorConsultationSlotMakingEdit"
                            defaultChecked={
                              ras.DoctorConsultationSlotMakingEdit
                            }
                            value={ras.DoctorConsultationSlotMakingEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Consultation : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationView"
                            defaultChecked={ras.consultationView}
                            value={ras.consultationView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationEdit"
                            defaultChecked={ras.consultationEdit}
                            value={ras.consultationEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Home Services :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Home Service View : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="HomeServiceView"
                            defaultChecked={ras.HomeServiceView}
                            value={ras.HomeServiceView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Home Service List : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceListView"
                            defaultChecked={ras.homeServiceListView}
                            value={ras.homeServiceListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceListAdd"
                            defaultChecked={ras.homeServiceListAdd}
                            value={ras.homeServiceListAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceListEdit"
                            defaultChecked={ras.homeServiceListEdit}
                            value={ras.homeServiceListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">New Home Service Request : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newHomeServiceRequestView"
                            defaultChecked={ras.newHomeServiceRequestView}
                            value={ras.newHomeServiceRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newHomeServiceRequestAdd"
                            defaultChecked={ras.newHomeServiceRequestAdd}
                            value={ras.newHomeServiceRequestAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newHomeServiceRequestEdit"
                            defaultChecked={ras.newHomeServiceRequestEdit}
                            value={ras.newHomeServiceRequestEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className=""> Home Service Slot Making : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="HomeServiceSlotMakingView"
                            defaultChecked={ras.HomeServiceSlotMakingView}
                            value={ras.HomeServiceSlotMakingView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="HomeServiceSlotMakingAdd"
                            defaultChecked={ras.HomeServiceSlotMakingAdd}
                            value={ras.HomeServiceSlotMakingAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newHomeServiceRequestEdit"
                            defaultChecked={ras.newHomeServiceRequestEdit}
                            value={ras.newHomeServiceRequestEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Home Service: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceView"
                            defaultChecked={ras.homeServiceView}
                            value={ras.homeServiceView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceEdit"
                            defaultChecked={ras.homeServiceEdit}
                            value={ras.homeServiceEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Payments :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Payments : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="paymentsView"
                            defaultChecked={ras.paymentsView}
                            value={ras.paymentsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Payments Reports :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Payments Reports View : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="PaymentsReportsView"
                            defaultChecked={ras.PaymentsReportsView}
                            value={ras.PaymentsReportsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Home Test Report : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeTestReportView"
                            defaultChecked={ras.homeTestReportView}
                            value={ras.homeTestReportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Investigations at HCU Report : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationAtHcuReportView"
                            defaultChecked={ras.investigationAtHcuReportView}
                            value={ras.investigationAtHcuReportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Consultation Report : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationReportView"
                            defaultChecked={ras.consultationReportView}
                            value={ras.consultationReportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Home Service Report : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceReportView"
                            defaultChecked={ras.homeServiceReportView}
                            value={ras.homeServiceReportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Settings :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Settings: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="SettingsView"
                            defaultChecked={ras.SettingsView}
                            value={ras.SettingsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Hospital Profile Request : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalProfileRequestView"
                            defaultChecked={ras.hospitalProfileRequestView}
                            value={ras.hospitalProfileRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalProfileRequestAdd"
                            defaultChecked={ras.hospitalProfileRequestAdd}
                            value={ras.hospitalProfileRequestAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Add
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Ticket Rasing List : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ticketRaisingListView"
                            defaultChecked={ras.ticketRaisingListView}
                            value={ras.ticketRaisingListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Techinical Support : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="TechinicalSupportView"
                            defaultChecked={ras.TechinicalSupportView}
                            value={ras.TechinicalSupportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="TechinicalSupportAdd"
                            defaultChecked={ras.TechinicalSupportAdd}
                            value={ras.TechinicalSupportAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            Add
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className=""> Manual : </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="manualView"
                            defaultChecked={ras.manualView}
                            value={ras.manualView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label">View</Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">CGHS Corner: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="cgsCornerView"
                            defaultChecked={ras.cgsCornerView}
                            value={ras.cgsCornerView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        <p className="">Settings: </p>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsView"
                            defaultChecked={ras.settingsView}
                            value={ras.settingsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <div className="mt-3" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-primary m-1"
                      >
                        Submit
                        <i
                          className="fa fa-check-circle-o"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Roles
