import React from "react"
import { Container, Col, Card, CardBody } from "reactstrap"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import Breadcrumbs from "../../components/Common/Breadcrumb"

function DoctorSchedule() {
    const events = [{ title: "", start: "" }]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Doctor" breadcrumbItem="Doctor Schedule" />

          <Card>
            <CardBody>
              <Col xl={12}>
                <Card>
                  <CardBody>
                  <FullCalendar
                          plugins={[dayGridPlugin]}
                          initialView="dayGridMonth"
                          events={events}
                        />
                  </CardBody>
                </Card>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DoctorSchedule
