import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
  Button,
  Modal,
  Form,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [modal_small1, setmodal_small1] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const [User, setUser] = useState([])

  const handleUser = e => {
    let myUser = { ...User }
    myUser[e.target.name] = e.target.value
    setUser(myUser)
  }

  const [form, setform] = useState([])

  const [Actin, setActin] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datas1 = data.user

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const Actinid = data => {
    sessionStorage.setItem("bookingidsser", data._id)
    history.push("/ViewHomeSlotService")
  }

  const getpopup1 = data => {
    tog_small1()
    setform(data)
    var user = datas1
    setUser(user)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  useEffect(() => {
    GetHospitals()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.getAllHomeServiceBookings,
        { hocId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.data)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editbookings()
  }

  const Editbookings = () => {
    var token = datas

    const dataArray = {
      bookingId: form._id,
      employeeName: User.userName,
      employeeId: User.userCode,
      HcuBookingId: form.HcuBookingId,
      status: form.status,
      description: form.description,
      hcuUserId: form.hcuUserId,
      doctorId: form.doctorId,
    }
    axios
      .post(URLS.AcceptHomeServiceBookingRequest, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            setmodal_small1(false)
            toast(res.data.message)
            GetHospitals()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [doctor, setdoctors] = useState([])

  useEffect(() => {
    GetDoctors()
  }, [])

  const GetDoctors = () => {
    var token = datas
    axios
      .post(
        URLS.gethomeprovider,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctors(res.data.data)
      })
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.getAllHomeServiceBookingsSearch + `${e.target.value}`,
        { hocId: datasd },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.data)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="ACTIN"
            breadcrumbItem="Accepting Home Service Slot Booking"
          />
          <Row>
            <Col>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                        </Col>
                      </Row>
                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>ACTIN Booking Id</th>
                              <th>ACTIN User Id</th>
                              <th>User Name</th>
                              <th>User Contact No</th>
                              <th>Age/Gender</th>
                              <th>User Address</th>
                              <th>Service Name</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>{data.bookingCode}</td>
                                <td>{data.userCode}</td>
                                <td>{data.name}</td>
                                <td>{data.phone}</td>
                                <td>{data.gender}</td>
                                <td>{data.address}</td>
                                <td className="text-start">
                                  {data.items.map((data, i) => (
                                    <>
                                      <li key={i}>{data.itemName}</li>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {data.slotDate}/{data.slotTime}
                                </td>

                                <td>
                                  {data.status == "canceled" ||
                                  data.status == "failed" ? (
                                    <>
                                      <span className="badge bg-danger">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {data.status == "completed" ? (
                                        <>
                                          <span className="badge bg-success">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <span className="badge bg-warning">
                                          {data.status}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    onClick={() => {
                                      Actinid(data)
                                    }}
                                    color="info"
                                    outline
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Button>

                                  {data.status == "pending" ||
                                  data.status == "booking confirmed" ? (
                                    <>
                                      {" "}
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          getpopup1(data)
                                        }}
                                        color="success"
                                        outline
                                      >
                                        <i className="bx bxs-user-check "></i>
                                      </Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>

      <Modal
        size="md"
        isOpen={modal_small1}
        toggle={() => {
          tog_small1()
        }}
        centered
      >
        <Form
          onSubmit={e => {
            handleSubmit1(e)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Accepting Bookings : <br></br>
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="mb-3">
              <label> Service Provider </label>{" "}
              <span className="text-danger">*</span>
              <select
                value={form.doctorId}
                name="doctorId"
                onChange={e => {
                  handleChange(e)
                }}
                required
                className="form-select"
              >
                <option value="">Select</option>
                {doctor.map((data, key) => {
                  return (
                    <option key={key} value={data._id}>
                      {data.firstName}
                      {data.lastName} ({data.speciality})
                    </option>
                  )
                })}
              </select>
            </div>
            {/* <div className="mb-3">
              <label>
                Employee Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter Employee Name "
                required
                value={form.employeeName}
                name="employeeName"
                onChange={e => {
                  handleChange(e)
                }}
              />
            </div>
            <div className="mb-3">
              <label>
                Employee Id <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter Employee Id "
                required
                value={form.employeeId}
                name="employeeId"
                onChange={e => {
                  handleChange(e)
                }}
              />
            </div> */}
            <div className="mb-3">
              <label>
                Employee Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter Employee Name "
                required
                value={User.userName}
                name="userName"
                onChange={e => {
                  handleUser(e)
                }}
              />
            </div>
            <div className="mb-3">
              <label>
                Employee Id <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter Employee Id "
                required
                value={User.userCode}
                name="userCode"
                onChange={e => {
                  handleUser(e)
                }}
              />
            </div>
            <div className="mb-3">
              <label>HCU Booking Id</label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter HCU Booking Id"
                value={form.HcuBookingId}
                name="HcuBookingId"
                onChange={e => {
                  handleChange(e)
                }}
              />
            </div>
            <div className="mb-3">
              <label>User Id</label>
              <input
                type="text"
                className="form-control"
                id="basicpill-firstname-input1"
                placeholder="Enter User Id"
                value={form.hcuUserId}
                name="hcuUserId"
                onChange={e => {
                  handleChange(e)
                }}
              />
            </div>

            <div className="mb-3">
              <label>
                Status<span className="text-danger">*</span>
              </label>
              <select
                className="form-select"
                required
                value={form.status}
                name="status"
                onChange={e => {
                  handleChange(e)
                }}
              >
                <option value="">Select</option>
                <option value="accept">Accept</option>
                <option value="rejected">Reject</option>
              </select>
            </div>

            {form.status == "rejected" ? (
              <div className="mb-3">
                <label>
                  Description <span className="text-danger">*</span>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Description "
                  required
                  rows={3}
                  onChange={e => {
                    handleChange(e)
                  }}
                  name="description"
                  value={form.description}
                />
              </div>
            ) : (
              ""
            )}

            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small1(false)
                }}
                color="danger"
                type="button"
              >
                Cancel <i className="fas fa-times-circle"></i>
              </Button>
              <Button className="m-1" color="info" type="submit">
                Submit <i className="fas fa-check-circle"></i>
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default Ventures
