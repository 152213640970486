import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, CardTitle } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

const Banner = () => {
  const [banner, setbanner] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    Getalldep()
  }, [])

  const Getalldep = () => {
    var token = datas
    axios
      .post(
        URLS.GetManual,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.manauls)
        setIsLoading(false)
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Manual" />
          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <CardTitle className="mb-5">Manual</CardTitle>
                      <Row>
                        {banner.map((data, key) => (
                          <Col md={6} key={key}>
                            {" "}
                            <div className="faq-box d-flex mb-4">
                              <div className="flex-shrink-0 me-3 faq-icon">
                                <i className="bx bx-help-circle font-size-20 text-success" />
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="font-size-15">{data.title}</h5>
                                <p className="text-muted">
                                  <iframe
                                    width="100%"
                                    height="300px"
                                    src={data.youtubeUrl}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                  ></iframe>
                                </p>
                              </div>
                            </div>{" "}
                          </Col>
                        ))}
                      </Row>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>{" "}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Banner
