import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import axios from "axios"
import Select from "react-select"

function AddVendors() {
  const [form, setform] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const [inputList, setInputList] = useState([{ info: "", advantage: "" }])

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { info: "", advantage: "" }])
  }
  const options = [
    { value: "BUN (Blood Urea Nitrogen)", label: "BUN (Blood Urea Nitrogen)" },
    {
      value: "CA 19-9 Blood Test (Pancreatic Cancer)",
      label: "CA 19-9 Blood Test (Pancreatic Cancer)",
    },
    {
      value: "CA-125 Blood Test (Ovarian Cancer)",
      label: "CA-125 Blood Test (Ovarian Cancer)",
    },
    // Add more options as needed
  ]

  const handleChange = selectedOptions => {
    // Do something with the selected options
    console.log(selectedOptions)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Add B2B" breadcrumbItem="Add B2B" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Row>
                    <h6
                      style={{ color: "#000", fontWeight: "bold" }}
                      className="mb-4"
                    >
                      Add B2B
                    </h6>

                    <Col lg="4">
                      <div className="mb-3 ">
                        <Label for="basicpill-firstname-input1">
                          Tests <span className="text-danger">*</span>
                        </Label>
                        <Select
                          isMulti
                          options={options}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="mb-3 ">
                        <Label for="basicpill-firstname-input1">
                          Price <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Price"
                          required
                          disabled
                          value={form.title}
                          name="title"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Date <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Date"
                          required
                          value={form.t1}
                          name="t1"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Time
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="time"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Employee Time"
                          required
                          value={form.t1}
                          name="t1"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Patient Ward No
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Patient Ward No"
                          required
                          value={form.t2}
                          name="t2"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <h6
                      style={{ color: "#000", fontWeight: "bold" }}
                      className="mb-2"
                    >
                      Customer Details
                    </h6>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Name of the Patient
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Name of the Patient"
                          required
                          value={form.t2}
                          name="t2"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Contact No<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Contact No"
                          required
                          value={form.t2}
                          name="t2"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Age <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Age"
                          required
                          value={form.t2}
                          name="t2"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Gender<span className="text-danger">*</span>
                        </Label>
                        <select
                          className="form-select"
                          required
                          onChange={e => {
                            handlechange(e)
                          }}
                        >
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Name of the Doctor
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Name of the Doctor"
                          required
                          value={form.t2}
                          name="t2"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Row>
                <div style={{ float: "right" }}>
                  <Button className="m-1" color="info" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
