import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import axios from "axios"
import Select from "react-select"
import { URLS } from "../../Url"

function AddVendors() {
  const [test, settest] = useState([])

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.getaddAllhometest,
        { hocId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        settest(res.data.tests)
      })
  }

  const [selectedOptions, setSelectedOptions] = useState([])

  const HealthScheme = selectedOptions => {
    setSelectedOptions(selectedOptions)
  }

  const options = test.map(response => ({
    value: response._id,
    label: response.title,
  }))

  const [form, setform] = useState([])
  const [files, setFiles] = useState([])
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var dataCommissionType = data.user.commissionType

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const [inputList, setInputList] = useState([{ packgaeInstruction: "" }])

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("hospitalId", datasd)
    dataArray.append("hcuPackageId", form.hcuPackageId)
    dataArray.append("hcuPackageName", form.hcuPackageName)
    dataArray.append("hcuPackagePrice", form.hcuPackagePrice)
    dataArray.append("packgaeInformation", form.packgaeInformation)
    dataArray.append("packgaeInstruction", JSON.stringify(inputList))
    dataArray.append("testsInPackage", JSON.stringify(selectedOptions))
    dataArray.append("hcuCommission", form.hcuCommission)

    for (let i = 0; i < files.length; i++) {
      dataArray.append("image", files[i])
    }

    axios
      .put(URLS.UpdatePackegeRequest + form._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Packages")
            sessionStorage.setItem(
              "tost",
              "Hospital has been Added Successfully"
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { packgaeInstruction: "" }])
  }

  useEffect(() => {
    GetOneActins()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("packageid")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.getonePackegeRequest, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.packagerequest)
        setSelectedOptions(res.data.packagerequest.testsInPackage)
        setInputList(res.data.packagerequest.packgaeInstruction)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Test" breadcrumbItem="Edit Package Request" />
          <Row>
            <Col xl="12">
              <div style={{ float: "right" }}>
                <Button
                  onClick={history.goBack}
                  className="mb-3  m-1 "
                  color="primary"
                  style={{ float: "right" }}
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        HCU Package Id <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter HCU Package Id"
                        required
                        disabled
                        value={form.hcuPackageId}
                        name="hcuPackageId"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        HCU Package Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter HCU Package Name"
                        required
                        value={form.hcuPackageName}
                        name="hcuPackageName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Public Price <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Public Price"
                        required
                        disabled
                        value={form.hcuPackagePrice}
                        name="hcuPackagePrice"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  {dataCommissionType == "Price" ? (
                    <>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            HCU Price{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter HCU Price"
                            required
                            disabled
                            value={form.hcuCommission}
                            name="hcuCommission"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                  <Col lg="6">
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        Tests In Package <span className="text-danger">*</span>
                      </Label>
                      <Select
                        options={options}
                        placeholder="Tests In Package"
                        value={selectedOptions}
                        onChange={HealthScheme}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Package Image
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter image"
                        name="image"
                        onChange={changeHandler}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Package Information{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Test Information"
                        required
                        rows={3}
                        value={form.packgaeInformation}
                        name="packgaeInformation"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div>
                      <Label>Package Instruction</Label>
                      <Row>
                        {inputList.map((x, i) => {
                          return (
                            <>
                              <Row>
                                <div key={i} className="box row">
                                  <Col md="9" sm="12" className="mb-3">
                                    <Input
                                      type="text"
                                      required
                                      name="packgaeInstruction"
                                      placeholder="Enter Test Instruction"
                                      value={x.packgaeInstruction}
                                      onChange={e => handleInputChange(e, i)}
                                    />
                                  </Col>

                                  <Col sm="2" md="3">
                                    <div className="btn-box">
                                      {inputList.length !== 1 && (
                                        <button
                                          className="mr10 btn btn-outline-danger btn-sm m-1"
                                          type="button"
                                          onClick={() => handleRemoveClick(i)}
                                        >
                                          Remove
                                          <i className="bx bx-x-circle"></i>
                                        </button>
                                      )}
                                      {inputList.length - 1 === i && (
                                        <button
                                          className="btn btn-sm btn-outline-info m-1"
                                          onClick={handleAddClick}
                                        >
                                          Add
                                          <i className="bx bx-plus-circle"></i>
                                        </button>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </Row>
                            </>
                          )
                        })}
                      </Row>
                    </div>
                  </Col>
                </Row>
                <div style={{ float: "right" }}>
                  <Button className="m-1" color="info" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
