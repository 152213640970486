import React, { useEffect, useState } from "react"
import { CardBody, Container, Row, Col, Card, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { CSVLink } from "react-csv"
import { URLS } from "../../Url"
import axios from "axios"
import jsPDF from "jspdf"
import "jspdf-autotable"

function PaymentReport() {
  const [Actin, setActin] = useState([])

  useEffect(() => {
    ActinReport()
  }, [])

  const history = useHistory()

  const [userInCsv, setuserInCsv] = useState([])

  const [Price, setPrice] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("Paymentid")

  const ActinReport = () => {
    var token = datas
    const data = { _id: Actinid }

    axios
      .post(URLS.GETONEPAYMENTGENERATE, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.paydetails)
        setPrice(res.data.MonthlyFinalPrice)
        setuserInCsv(res.data.paydetails)
      })
  }
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const csvReport = {
    filename: "Payment_Report",
    data: userInCsv,
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A2"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "S.No",
        "ACTIN Booking Id",
        "HCU User Id",
        "HCU Booking Id",
        "Booking Date",
        "Booking Status",
        "Final Amount ",
        "Final Amount",
        "Coupon Discount",
        "Health Scheme",
        "Gst Charges",
        "HCU Share ",
        "ACTIN Share ",
        "HCU Bill No ",
        "HCU Receipt No",
        "Payment Date",
        "Payment Id ",
        "Status",
      ],
    ]

    const data = Actin.map((elt, i) => [
      i + 1,
      elt.actinBookingCode,
      elt.hcuUserCode,
      elt.actinHocCode,
      elt.bookingDate,
      elt.bookingStatus,
      elt.subTotal,
      elt.refundAmount,
      elt.couponDiscount,
      elt.cardName,
      elt.gst,
      elt.hcuShare,
      elt.actinShare,
      elt.hcuBillNumber,
      elt.hcuRecieptNumber,
      elt.paymentDate,
      elt.transactionId,
      elt.paymentStatus,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Payment_Report.pdf")
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="ACTIN" breadcrumbItem="Payments" />

            <Row>
              <Col>
                <Button
                  onClick={() => history.goBack()}
                  className="mb-3  m-1 "
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i> Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <div>
                  <div className="table-responsive" style={{ height: "500px" }}>
                    <div className="col-sm-12">
                      <div style={{ float: "right" }}>
                        <Row>
                          <Col>
                            <div style={{ float: "right" }}>
                              <CSVLink {...csvReport}>
                                <button
                                  className="btn btn-success me-2"
                                  type="submit"
                                >
                                  <i className="fas fa-file-excel"></i> Excel
                                </button>
                              </CSVLink>
                              <Button
                                type="button"
                                className="btn btn-danger "
                                onClick={exportPDF}
                              >
                                <i className="fas fa-file-pdf"></i> Pdf
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="mt-3">
                      <Table
                        id="empTable"
                        className="table table-bordered mb-3 mt-5"
                      >
                        <thead className="text-center">
                          <tr>
                            <th>S.No</th>
                            <th>ACTIN Booking Id</th>
                            <th>HCU User Id</th>
                            <th>HCU Booking Id</th>
                            <th>Booking Date</th>
                            <th>Booking Status</th>
                            <th>Final Amount </th>
                            <th>Refund Amount</th>
                            <th>Coupon Discount</th>
                            <th>Health Scheme</th>
                            <th>Gst Charges</th>
                            <th>HCU Share </th>
                            <th>ACTIN Share </th>
                            {/* <th>HCU Bill No </th>
                            <th>HCU Receipt No</th>
                            <th>Payment Date</th>
                            <th>Payment Id </th>
                            <th>Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key} className="text-center">
                              <td>{(pageNumber - 1) * 20 + key + 21}</td>
                              <td>{data.actinBookingCode}</td>
                              <td>{data.hcuUserCode}</td>
                              <td>{data.actinHocCode}</td>
                              <td>{data.bookingDate}</td>
                              <td>
                                {data.bookingStatus == "canceled" ||
                                data.bookingStatus == "failed" ? (
                                  <>
                                    <span className="badge bg-danger">
                                      {data.bookingStatus}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    {data.bookingStatus == "completed" ? (
                                      <>
                                        <span className="badge bg-success">
                                          {data.bookingStatus}
                                        </span>
                                      </>
                                    ) : (
                                      <span className="badge bg-warning">
                                        {data.bookingStatus}
                                      </span>
                                    )}
                                  </>
                                )}
                              </td>
                              <td>{data.subTotal}</td>
                              <td>{data.refundAmount}</td>
                              <td>{data.couponDiscount}</td>
                              <td>{data.cardName}</td>
                              <td>{data.gst}</td>
                              <td>{data.hcuShare}</td>
                              <td>{data.actinShare}</td>
                              {/* <td>{data.hcuBillNumber}</td>
                              <td>{data.hcuRecieptNumber}</td>
                              <td>{data.paymentDate}</td>
                              <td>{data.transactionId}</td>
                              <td>{data.paymentStatus}</td> */}
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr className="text-center">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total </td>
                            <td>{Price}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td> */}
                          </tr>
                        </tfoot>
                      </Table>
                    </div>
                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default PaymentReport
