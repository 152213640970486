import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"
import { useHistory } from "react-router-dom"

function Support() {
  const [modal_small, setmodal_small] = useState(false)

  const [modal_small1, setmodal_small1] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [banner, setbanner] = useState([])

  const [form, setform] = useState([])

  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const handlechange1 = e => {
    const myform = { ...form1 }
    myform[e.target.name] = e.target.value
    setform1(myform)
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddRequest()
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    AddRequest1()
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  useEffect(() => {
    GetAllBanners()
  }, [])

  const AddRequest = () => {
    var token = datas
    const dataArray = {
      monthYear: form.monthYear,
      description: form.description,
      hcuBillNumber: form.hcuBillNumber,
      hospitalId: datasd,
    }
    axios
      .post(URLS.AddPAYMENTGENERATE, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetAllBanners()
            clearForm()
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const AddRequest1 = () => {
    var token = datas
    const dataArray = {
      hcuRecieptNumber: form1.hcuRecieptNumber,
    }
    axios
      .put(URLS.UpdateONEPAYMENT + form1._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small1(false)
            GetAllBanners()
            clearForm()
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const clearForm = () => {
    setform({
      monthYear: "",
      description: "",
    })
  }

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.GetPAYMENTGENERATE,
        { hospitalId: datasd, monthYear: "" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.payments)
        setIsLoading(false)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const getpopup = () => {
    tog_small()
  }
  const getpopup1 = data => {
    tog_small1()
    setform1(data)
  }

  const ZoneIdszs = data => {
    sessionStorage.setItem("Paymentid", data._id)
    history.push("/ViewPayment")
  }


  useEffect(() => {
    const currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth(); // getMonth() returns 0 for January

    // Adjust month to one month before the current month
    if (month === 0) {
      month = 12; // December of the previous year
      year -= 1;
    } else {
      month = month; // JavaScript months are 0-indexed (0 for January, 11 for December)
    }

    const formattedMonth = String(month).padStart(2, '0'); // Ensure month is two digits
    const maxDate = `${year}-${formattedMonth}`;

    setMaxMonth(maxDate);
  }, []);

  const [maxMonth, setMaxMonth] = useState('');

  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Actin" breadcrumbItem="Payment Generate" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardHeader className="bg-white">
                      <CardTitle>
                        <Button
                          onClick={() => {
                            getpopup()
                          }}
                          className="mr-2"
                          style={{
                            padding: "6px",
                            margin: "3px",
                            background: "#b30706",
                          }}
                        >
                          Payment Generate
                        </Button>
                      </CardTitle>
                    </CardHeader>

                    <CardBody>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "500px" }}
                        >
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead className="text-center">
                              <tr>
                                <th>S.No</th>
                                <th>Year / Month </th>
                                <th>Generate - Date / Time</th>
                                <th>Amount</th>
                                <th>Actin Amount</th>
                                <th>TDS</th>
                                <th>Description</th>
                                <th>Hcu Bill Number </th>
                                <th>Hcu ReceiptNumber </th>
                                <th>Transaction Id</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody className="text-center">
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                  <td>{data.monthYear}</td>
                                  <td>
                                    {data.logCreatedDate.slice(0, 10)} /
                                    {new Date(
                                      data.logCreatedDate
                                    ).toLocaleTimeString("en-US", {
                                      hour12: false,
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      timeZone: "Asia/Kolkata",
                                    })}
                                  </td>
                                  <td>{data.hospitalAmount}</td>
                                  <td>{data.actinAmount}</td>
                                  <td>{data.tds}</td>
                                  <td>{data.description}</td>
                                  <td>{data.hcuBillNumber}</td>
                                  <td>{data.hcuRecieptNumber}</td>
                                  <td>{data.transactionId}</td>
                                  <td>
                                    {" "}
                                    {data.status == "Pending" ? (
                                      <>
                                        <span className="badge bg-warning">
                                          {data.status}
                                        </span>
                                      </>
                                    ) : (
                                      <span className="badge bg-success">
                                        {data.status}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      onClick={() => {
                                        ZoneIdszs(data)
                                      }}
                                      className="mr-2"
                                      style={{
                                        padding: "6px",
                                        margin: "3px",
                                      }}
                                      color="info"
                                      outline
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="fas fa-eye"
                                      ></i>
                                    </Button>

                                    {data.status == "approved" ? (
                                      <>
                                        {" "}
                                        <Button
                                          onClick={() => {
                                            getpopup1(data)
                                          }}
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="warning"
                                          outline
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="bx bx-repost"
                                          ></i>
                                        </Button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Payment Generate
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit(e)
              }}
            >
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Month / Year <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="month"
                    className="form-control"
                    id="basicpill-firstname-input2"
                    placeholder="Enter Month Year"
                    required
                    // max={maxMonth}
                    value={form.monthYear}
                    name="monthYear"
                    onChange={e => {
                      handlechange(e)
                    }}

                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Hcu Bill Number<span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Hcu Bill Number"
                    required
                    value={form.hcuBillNumber}
                    name="hcuBillNumber"
                    onChange={e => {
                      handlechange(e)
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Description</Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description"
                    name="description"
                    value={form.description}
                    onChange={e => {
                      handlechange(e)
                    }}
                  />
                </div>
              </Col>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Payment Generate
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Hcu ReceiptNumber<span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Hcu ReceiptNumber"
                    required
                    value={form1.hcuRecieptNumber}
                    name="hcuRecieptNumber"
                    onChange={e => {
                      handlechange1(e)
                    }}
                  />
                </div>
              </Col>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Support
