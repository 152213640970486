import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "../../Url"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles1, setselectedFiles1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const handleSubmit = e => {
    e.preventDefault()

    if (selectedFiles.length > 0) {
      Adddealer()
    } else {
      toast("Please upload image")
    }
  }

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("hospitalId", datasd)
    dataArray.append("firstName", form.firstName)
    dataArray.append("lastName", form.lastName)
    dataArray.append("phone", form.phone)
    dataArray.append("email", form.email)
    dataArray.append("joinDate", form.joinDate)
    dataArray.append("experience", form.experience)
    dataArray.append("doctorEmployId", form.doctorEmployId)
    dataArray.append("doctorQualification", form.doctorQualification)
    dataArray.append("tarrifPrice", form.tarrifPrice)
    dataArray.append("locationInHoc", form.locationInHoc)
    dataArray.append("password", form.password)
    dataArray.append("registrationNumber", form.registrationNumber)
    dataArray.append("specialityId", form.specialityId)
    dataArray.append("filedOfMedicine", form.filedOfMedicine)
    dataArray.append("description", form.description)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("employImage", selectedFiles[i])
    }

    for (let i = 0; i < selectedFiles1.length; i++) {
      dataArray.append("registrationImage", selectedFiles1[i])
    }

    axios
      .post(URLS.AddDoctor, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/DoctorProfile")
            clearForm()
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      company_name: "",
      company_mobile_number: "",
      trading_number: "",
      trading_image: "",
      vat: "",
      address: "",
      supportEmail: "",
      bookingEmail: "",
      cityId: "",
      name: "",
      email: "",
      password: "",
      phone: "",
      logo: "",
    })
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes1(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes1(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [speciality, setspeciality] = useState([])

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getspeciality(e.target.value)
  }

  const getspeciality = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("filedOfMedicine", data)
    axios
      .post(URLS.GetByspeciality, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setspeciality(res.data.specialities)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Add Doctor" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4"
                      style={{ color: "#0079FF", fontWeight: "bold" }}
                    >
                      General Information
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter  Name"
                        required
                        value={form.firstName}
                        pattern="^[a-zA-Z ]*$"
                        name="firstName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter  Name"
                        pattern="^[a-zA-Z ]*$"
                        required
                        value={form.lastName}
                        name="lastName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Phone <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Phone"
                        required
                        minLength="10"
                        maxLength="10"
                        pattern="[0-9]+"
                        value={form.phone}
                        name="phone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Email<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Email"
                        required
                        value={form.email}
                        name="email"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Password<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Password"
                        required
                        value={form.password}
                        name="password"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Profile</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <span>
                            Image format - jpg, png, jpeg, gif Image Size -
                            maximum size 2 MB Image Ratio - 1:1
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Date of Joining
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Join Date"
                        required
                        value={form.joinDate}
                        name="joinDate"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Experience
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Experience"
                        required
                        value={form.experience}
                        name="experience"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Employee Id
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Employee Id"
                        required
                        value={form.doctorEmployId}
                        name="doctorEmployId"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Qualification
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Qualification"
                        required
                        value={form.doctorQualification}
                        name="doctorQualification"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Tariff / Cost<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Tariff / Cost"
                        required
                        value={form.tarrifPrice}
                        name="tarrifPrice"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Location in HCU
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Location in HCU"
                        value={form.locationInHoc}
                        name="locationInHoc"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row className="mt-2">
              <Col lg="6">
                <Card>
                  <CardBody>
                    <h5
                      className="mb-2"
                      style={{ color: "#0079FF", fontWeight: "bold" }}
                    >
                      Registation Details
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        State / Mci Reg No
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter State / Mci Reg No "
                        value={form.registrationNumber}
                        name="registrationNumber"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="w-50 mt-5 mb-4">
                      <h5 style={{ fontWeight: "bold" }} className="mt-3">
                        State Registation Certificate
                      </h5>
                      <div className="w-100">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles1(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-1">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Upload Certificate</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-1"
                          id="file-previews"
                        >
                          {selectedFiles1.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <h5
                      className="mb-2"
                      style={{ color: "#0079FF", fontWeight: "bold" }}
                    >
                      Information
                    </h5>
                    <Row>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input3">
                          Field of Medicine
                          <span className="text-danger">*</span>
                        </Label>

                        <select
                          className="form-select"
                          required
                          value={form.filedOfMedicine}
                          name="filedOfMedicine"
                          onChange={e => {
                            Optionchange(e)
                          }}
                        >
                          <option value=""> Select</option>
                          <option value="Allopathic Medicine">
                            Allopathic Medicine
                          </option>
                          <option value="Ayurvedic Medicine">
                            Ayurvedic Medicine
                          </option>
                          <option value="Homeopathic Medicine">
                            Homeopathic Medicine
                          </option>
                          <option value="Unani">UNANI</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input3">
                          Speciality <span className="text-danger">*</span>
                        </Label>

                        <select
                          className="form-select"
                          required
                          value={form.specialityId}
                          name="specialityId"
                          onChange={e => {
                            handlechange(e)
                          }}
                        >
                          <option value=""> Select </option>

                          {speciality.map((data, key) => {
                            return (
                              <option key={key} value={data.value}>
                                {data.label}
                              </option>
                            )
                          })}
                        </select>
                      </div>{" "}
                      <div className="mb-5">
                        <Label for="basicpill-firstname-input1">
                          Description
                        </Label>
                        <textarea
                          type="text"
                          rows="7"
                          className="form-control "
                          id="basicpill-firstname-input1"
                          placeholder="Enter Description"
                          value={form.description}
                          name="description"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-check-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
