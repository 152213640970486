import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Button,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import gig from "../../assets/images/loders.gif"
import nodata from "../../assets/images/nodata.gif"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"

function AddVendors() {
  const [hometest, sethometest] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  // const handleInputChange = (key, index, value) => {
  //   const newDataArray = [...hometest]
  //   newDataArray[index][key] = value
  //   sethometest(newDataArray)
  // }

  const handleInputChange = (_id, key, value) => {
    const newDataArray = [...hometest]
    console.log(newDataArray)
    const index = newDataArray.findIndex(item => item._id === _id)
    if (index !== -1) {
      newDataArray[index][key] = value
      sethometest(newDataArray)
    } else {
      console.error(`Item with _id ${_id} not found in hometest array.`)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const validationErrors = []

    hometest.forEach((data, index) => {
      if (data.hocTestPrice && !data.hocTestid) {
        validationErrors.push(
          `Investigate Price is required when Investigate Test Id is entered for row ${
            index + 1
          }`
        )
      }

      if (data.hocTestid && !data.hocTestPrice) {
        validationErrors.push(
          `Investigate Test Id is required when Investigate Price is entered for row ${
            index + 1
          }`
        )
      }

      if ((data.hocTestPrice || data.hocTestid) && data.checkstatus !== true) {
        validationErrors.push(
          `Status must be "true" or "false" for row ${index + 1}`
        )
      }
    })

    if (validationErrors.length > 0) {
      validationErrors.forEach(error => {
        toast.error(error)
      })
    } else {
      var token = datas
      const dataArray = {
        hocId: datasd,
        data: hometest,
      }

      axios
        .post(URLS.AddHomeServiceslist, dataArray, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(
          res => {
            if (res.status === 200) {
              toast(res.data.message)
              history.push("/HomeServiceList")
              sessionStorage.setItem(
                "tost",
                "Home Service has been Added Successfully"
              )
            }
          },
          error => {
            if (error.response && error.response.status === 400) {
              toast(error.response.data.message)
            }
          }
        )
    }
  }

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    const dataArray = {
      hocId: datasd,
    }
    axios
      .post(URLS.GetadminHomeServiceslist, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        sethometest(res.data.services)
        setIsLoading(false)
      })
  }

  const [Searchs, setSearchs] = useState("")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Actin" breadcrumbItem="List Of Home Services" />
          <Row>
            <Col xl="12">
              <div style={{ float: "right" }}>
                <Button
                  onClick={history.goBack}
                  className="mb-3  m-1 "
                  color="primary"
                  style={{ float: "right" }}
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card className="mb-5 ">
              {isLoading == true ? (
                <>
                  <div
                    style={{ zIndex: "9999999999999", height: "420px" }}
                    className="text-center mt-5 pt-5"
                  >
                    <img src={gig} height="140px"></img>
                    <div>Loading......</div>
                  </div>
                </>
              ) : (
                <>
                  {hometest.length > 0 ? (
                    <>
                      <CardBody>
                        <Row>
                          <Col></Col>
                          <Col>
                            <div style={{ float: "right" }}>
                              <input
                                type="text"
                                // name="search"
                                // value={Searchs.search}
                                // onChange={Search}
                                onChange={e => {
                                  setSearchs(e.target.value)
                                }}
                                className="form-control"
                                placeholder="Search.."
                              />
                            </div>
                          </Col>
                        </Row>
                        <div
                          className="table-responsive"
                          style={{ height: "500px" }}
                        >
                          <Table className="table table-bordered mb-5 mt-3">
                            <thead>
                              <tr className="text-center">
                                <th>S.No</th>
                                <th>Actin Service Id</th>
                                <th>Actin Service Name</th>
                                <th>Actin Service Information</th>
                                <th>HCU Service Id</th>
                                <th>HCU Service Price</th>
                                <th>Add</th>
                              </tr>
                            </thead> 
                            <tbody className="text-center">
                              {hometest
                                .filter(value => {
                                  if (Searchs == !null) {
                                    return value
                                  } else if (
                                    value?.title
                                      .toLowerCase()
                                      .includes(Searchs.toLowerCase())
                                  ) {
                                    return value
                                  }
                                })
                                .map((data, index) => (
                                  <tr key={data._id} className="text-center">
                                    <td>{index + 1}</td>
                                    <td>{data.testid}</td>
                                    <td>{data.title}</td>
                                    <td>{data.information.slice(0, 50)}...</td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter HCU TEST ID"
                                        value={data.hocTestid}
                                        onChange={e =>
                                          handleInputChange(
                                            data._id,
                                            "hocTestid",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter HCU Price"
                                        value={data.hocTestPrice}
                                        onChange={e =>
                                          handleInputChange(
                                            data._id,
                                            "hocTestPrice",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>

                                    <td>
                                      <div className="form-check form-switch form-switch-md mb-3">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={`customSwitchsizemd${data._id}`}
                                          name="checkstatus"
                                          defaultChecked={
                                            data.checkstatus == true
                                          }
                                          value={data.checkstatus}
                                          onChange={e =>
                                            handleInputChange(
                                              data._id,
                                              "checkstatus",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          <div style={{ float: "right" }}>
                            <Button
                              className="m-1"
                              color="primary"
                              type="submit"
                            >
                              Submit <i className="fas fa-check-circle"></i>
                            </Button>
                          </div>
                        </div>{" "}
                      </CardBody>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        style={{ zIndex: "9999999999999", height: "440px" }}
                        className="text-center mt-5 pt-5"
                      >
                        <img src={nodata} height="300px"></img>
                      </div>
                    </>
                  )}
                </>
              )}
            </Card>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
