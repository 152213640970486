import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import { ToastContainer, toast } from "react-toastify"

function AddVendors() {
  const [forms, setforms] = useState([])

  const handlechanges = e => {
    const myform = { ...forms }
    myform[e.target.name] = e.target.value
    setforms(myform)
  }

  const [price, setprice] = useState([])

  const handlechangess = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetbyDoctorsprice,
        { doctorId: e.target.value },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setprice(res.data.data)
      })
  }

  const history = useHistory()

  const [form, setForm] = useState({
    fromtime: "",
    totime: "",
    duration: "",
  })

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setForm(myform)
  }

  const generateTimeSlots = (fromtime, totime, duration) => {
    const timeSlots = []

    let currentTime = fromtime
    while (currentTime < totime) {
      timeSlots.push({
        fromtime: currentTime,
        totime: addMinutes(currentTime, duration),
      })
      currentTime = addMinutes(currentTime, duration)
    }

    return timeSlots
  }

  function addMinutes(time, minutes) {
    const [hours, originalMinutes] = time.split(":")
    const totalMinutes =
      parseInt(hours) * 60 + parseInt(originalMinutes) + minutes
    const newHours = Math.floor(totalMinutes / 60)
    const newMinutes = totalMinutes % 60
    return `${newHours.toString().padStart(2, "0")}:${newMinutes
      .toString()
      .padStart(2, "0")}`
  }

  const [inputList, setInputList] = useState([])

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { fromtime: "", totime: "" }])
  }

  const handleGenerateTimeSlots = () => {
    const { fromtime, totime, duration } = form
    if (fromtime && totime && duration) {
      const newTimeSlots = generateTimeSlots(
        fromtime,
        totime,
        parseInt(duration)
      )
      setInputList(newTimeSlots)
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId
 
  const Adddealer = () => {
    var token = datas

    const data = {
      _id: forms._id,
      doctorId: forms.doctorId,
      tarrriefCost: price.tarrifPrice,
      location: price.locationInHoc,
      employeeName: forms.employeeName,
      employeeId: forms.employeeId,
      fromtime: form.fromtime,
      totime: form.totime,
      duration: form.duration,
      timings: inputList,
    }

    axios
      .post(URLS.UpdateDoctorlots, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Consultation")
            sessionStorage.setItem(
              "tost",
              "Doctors slot has been Updated Successfully"
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const [Actin, setActin] = useState([])

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllDoctor,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.dctr)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    GetOneActins()
  }, [])

  const Actinid = sessionStorage.getItem("Timeslotid")

  const GetOneActins = () => {
    const dataS = {
      _id: Actinid,
    }
    var token = datas
    axios
      .post(URLS.GetOneDoctorSlot, dataS, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.booking[0])
        setForm(res.data.booking[0])
        setInputList(res.data.booking[0].timings)

        axios
          .post(
            URLS.GetbyDoctorsprice,
            { doctorId: res.data.booking[0].doctorId },

            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(res => {
            setprice(res.data.data)
          })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Time Schedule"
            breadcrumbItem="Edit Doctors Slot Making"
          />

          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Row>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Investigation Name
                          <span className="text-danger">*</span>
                        </Label>
                        <select
                          className="form-select"
                          required
                          onChange={e => {
                            handlechangess(e)
                          }}
                          value={forms.doctorId}
                          name="doctorId"
                        >
                          <option value="">Select</option>
                          {Actin.map((data, key) => {
                            return (
                              <option key={key} value={data._id}>
                                {data.doctorEmployId} / {data.firstName}
                                {data.lastName}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          HCU Price
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="HCU Price"
                          required
                          value={price.tarrifPrice}
                          name="price"
                          disabled
                        />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Location in HCU
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Location in HCU"
                          required
                          disabled
                          value={price.locationInHoc}
                          name="locationInHoc"
                        />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Employee Name
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Employee Name"
                          required
                          value={forms.employeeName}
                          name="employeeName"
                          onChange={e => {
                            handlechanges(e)
                          }}
                        />
                      </div>
                    </Col>

                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Employee Id<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Employee Id"
                          required
                          value={forms.employeeId}
                          name="employeeId"
                          onChange={e => {
                            handlechanges(e)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <div>
                    <Row>
                      <Col md="4" sm="12" className="mb-1">
                        <Label>From Time</Label>
                        <Input
                          type="time"
                          name="fromtime"
                          required
                          placeholder="Enter From Time"
                          value={form.fromtime}
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </Col>

                      <Col md="4" sm="12" className="mb-1">
                        <Label>To Time</Label>
                        <Input
                          type="time"
                          name="totime"
                          placeholder="Enter To Time"
                          value={form.totime}
                          required
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </Col>

                      <Col md="4" sm="12" className="mb-1">
                        <Label>Minutes</Label>
                        <Input
                          type="number"
                          name="duration"
                          required
                          placeholder="Enter Minutes"
                          value={form.duration}
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </Col>
                    </Row>
                    <button
                      className="btn btn-primary mt-4 mb-3"
                      onClick={handleGenerateTimeSlots}
                      type="button"
                    >
                      Generate Time Slots
                    </button>

                    {inputList.map((x, i) => (
                      <Row key={i}>
                        <div className="box row">
                          <Col md="4" sm="12" className="mb-1">
                            <Label>From Time</Label>
                            <Input
                              type="time"
                              name="fromtime"
                              required
                              placeholder="Enter From Time"
                              value={x.fromtime}
                              onChange={e => handleInputChange(e, i)}
                            />
                          </Col>

                          <Col md="4" sm="12" className="mb-1">
                            <Label>To Time</Label>
                            <Input
                              type="time"
                              name="totime"
                              placeholder="Enter To Time"
                              value={x.totime}
                              required
                              onChange={e => handleInputChange(e, i)}
                            />
                          </Col>

                          <Col sm="2">
                            <Label className="mt-1"></Label>
                            <div className="btn-box">
                              {inputList.length !== 1 && (
                                <button
                                  className="mr10 btn btn-outline-danger btn-sm m-1 mt-3"
                                  type="button"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  Remove <i className="bx bx-x-circle"></i>
                                </button>
                              )}
                              {inputList.length - 1 === i && (
                                <button
                                  className="btn btn-sm btn-outline-info m-1 mt-3"
                                  onClick={handleAddClick}
                                >
                                  Add <i className="bx bx-plus-circle"></i>
                                </button>
                              )}
                            </div>
                          </Col>

                          <Col sm="2" className="mt-5">
                            <p style={{ fontWeight: "bold" }}>Slot : {i + 1}</p>
                          </Col>
                        </div>
                      </Row>
                    ))}
                  </div>
                </Row>

                <div style={{ float: "right" }}>
                  <Button className="m-1 mt-5" color="info" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
