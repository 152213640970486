import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show")

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add("mm-active")
          parent3.childNodes[0].classList.add("mm-active")
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add("mm-show")
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show")
              parent5.childNodes[0].classList.add("mm-active")
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.user?.permissions[0]

  const Roles1 = data.user?.serviceList.includes("Test At Home")
  const Roles2 = data.user?.serviceList.includes("Investigations at HCU")
  const Roles3 = data.user?.serviceList.includes("Consultations")
  const Roles4 = data.user?.serviceList.includes("Home Services")

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {Roles?.Dashview === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/dashboard">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.hospitalListView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/HospitalProfile">
                    <i className="bx bxs-map"></i>
                    <span>{props.t("Profile")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.EmployeeManagmentView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-wallet-alt"></i>
                    <span>{props.t("Staff Managment")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.departementView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Departments">
                            {props.t("Departments")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.rolesAndPermissionView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/RolesPremissions">
                            {props.t("Roles & Premissions")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.staffView === true || Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Staff">{props.t("Staff")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.TestsAtHomeView === true || Roles?.accessAll === true ? (
              <>
                {Roles1 === false ? (
                  ""
                ) : (
                  <>
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="bx bxs-book"></i>
                        <span>{props.t("Tests at Home")}</span>
                      </Link>

                      <ul className="sub-menu">
                        {Roles?.homeTestListView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/Tests">
                                {props.t("Home Tests List")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        {Roles?.newTestsRequestView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/TestRequestlist">
                                {props.t("Tests Request List")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        {Roles?.packagesRequestView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/Packages">
                                {props.t("Package Request List")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        {Roles?.homeBookingListView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/TestList">
                                {props.t("Home Booking Tests")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        {Roles?.actInstantBookingListView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/Actinstant">
                                {props.t("ACT-INstant")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        {Roles?.emergencyBookingListView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/EmergencyBookings">
                                {props.t("Emergency Bookings")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        {Roles?.homeBookingListView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/PackagesBookings">
                                {props.t("Packages Bookings")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </li>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {Roles?.InvestigationsAtHCUView === true ||
            Roles?.accessAll === true ? (
              <>
                {Roles2 === false ? (
                  ""
                ) : (
                  <>
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="bx bxs-building-house"></i>
                        <span>{props.t("Investigations at HCU")}</span>
                      </Link>
                      <ul className="sub-menu">
                        {Roles?.investigationsListView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/Investigationlist">
                                {props.t("Investigation List")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        {Roles?.investigationsRequestsView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/Investigationrequestlist">
                                {props.t("Investigation Request List")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        {Roles?.InvestigationSlotMakingView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/Timeslotlist">
                                {props.t("Investigation Slot Making")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}

                        {Roles?.investigationAtHcuView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/AcceptingInvestigation">
                                {props.t("Investigation Acceptance")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </li>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {Roles?.DoctorConsultationsView === true ||
            Roles?.accessAll === true ? (
              <>
                {Roles3 === false ? (
                  ""
                ) : (
                  <>
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="fas fa-hospital-user"></i>
                        <span>{props.t("Doctor Consultation")}</span>
                      </Link>
                      <ul className="sub-menu">
                        {Roles?.DoctorConsultationAdd === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/DoctorProfile">
                                {props.t("Doctor List")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {Roles?.DoctorConsultationSlotMakingView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/Consultation">
                                {props.t("Consultation Slot Making")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {Roles?.consultationView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/Users">
                                {props.t("Consultation Acceptance")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </li>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {Roles?.HomeServiceView === true || Roles?.accessAll === true ? (
              <>
                {Roles4 === false ? (
                  ""
                ) : (
                  <>
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="bx bxs-home"></i>
                        <span>{props.t("Home Services")}</span>
                      </Link>
                      <ul className="sub-menu">
                      {Roles?.DoctorConsultationAdd === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/HomeServiceProviderList">
                                {props.t("Service Provider List")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {Roles?.homeServiceListView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/HomeServiceList">
                                {props.t("Home Service List")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {Roles?.newHomeServiceRequestView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/HomeServiceRequestlist">
                                {props.t("Home Service Request list")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {Roles?.HomeServiceSlotMakingAdd === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/HomeService">
                                {props.t("Home Service Slot Making")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {Roles?.homeServiceView === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <li>
                              <Link to="/HomeServiceSlotList">
                                {props.t("Home Service Acceptance")}
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </li>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {Roles?.PaymentsReportsView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-file-doc"></i>
                    <span>{props.t("Reports")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.homeTestReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles1 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/BookingsTestsReport">
                                {props.t("Home Tests Report")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ActinstantReport === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles1 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/ActinstantReport">
                                {props.t("ACT-INstant Report")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.homeTestReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles1 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/EmergencyReport">
                                {props.t("Emergency Report")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.homeTestReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles1 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/PackagesReport">
                                {props.t("Packages Report")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.investigationAtHcuReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles2 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/AcceptingInvestigationsReport">
                                {props.t("Investigation Report")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.consultationReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles3 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/DoctorConsultationReport">
                                {props.t("Consultation Report")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.homeServiceReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles4 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/HomeServiceReport">
                                {props.t("Home Service Report")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {Roles?.paymentsView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-receipt"></i>
                    <span>{props.t("Payments Managment")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/Payments">{props.t("Payments")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.ticketRaisingListView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-quote-right"></i>
                    <span>{props.t("Ticket Rasing List")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.ticketRaisingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles1 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/TicketsRasingList">
                                {props.t("Home Tests Tickets Rasing")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ticketRaisingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles2 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/PackagesTicketRasinglist">
                                {props.t("Packages Ticket Rasing")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ticketRaisingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles2 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/InvestigationsTickets">
                                {props.t("Investigation Tickets Rasing")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ticketRaisingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles3 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/ConsultationTickets">
                                {props.t("Consultation Tickets Rasing ")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ticketRaisingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        {Roles4 === false ? (
                          ""
                        ) : (
                          <>
                            <li>
                              <Link to="/HomeServiceTickets">
                                {props.t("Home Service Tickets Rasing")}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.settingsView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-cogs"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>

                  <ul className="sub-menu">
                    {Roles?.hospitalProfileRequestView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/ProfileUpdateRequest">
                            {props.t("Request For Profile")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.TechinicalSupportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Support">
                            {props.t("Technical Support")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.manualView === true || Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Manual">{props.t("Manual")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.cgsCornerView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/ContactUs">{props.t("Contact Us")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.SettingsView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/About">{props.t("About")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.SettingsView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/PrivicyPolicy">
                            {props.t("Privacy Policy")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.SettingsView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/RefundPolicy">
                            {props.t("Refund Policy")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.SettingsView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Termsconditions">
                            {props.t("Terms & Conditions")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
