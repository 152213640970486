import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"

function DoctorView() {
  const [forms, setforms] = useState([])
  const [forms1, setforms1] = useState([])

  const history = useHistory()
  useEffect(() => {
    GetOneActins()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("Doctorids")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.ViewDoctor, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.doctorResult)
        setforms1(res.data.doctorResult.registrationImage)
      })
  }

  return (
    <div>
      {" "}
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="ACTIN" breadcrumbItem="Doctor Profile" />
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>{" "}
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={4}>
                    <h5 className="text-primary"> Doctor Profile :</h5>
                    <Col md={12}>
                      <div className="text-center">
                        <div className="avatar-md1 profile-user-wid mb-4 mt-1">
                          <span className="ml-5">
                            <img
                              src={URLS.Base + forms.employImagelogo}
                              alt=""
                              className="avatar-md1 rounded-circle img-thumbnail"
                              height="150"
                              width="150"
                            />
                          </span>
                        </div>
                        <h5 className="mt-1 mb-1">
                          {forms.firstName}/{forms.lastName}
                        </h5>
                        <h6 className="mb-0 text-truncate ">
                          <i
                            style={{ fontSize: "20px" }}
                            className="bx bx-smile  text-warning"
                          ></i>{" "}
                          {forms.smiles}
                        </h6>
                      </div>
                    </Col>

                    <ul className="list-unstyled vstack gap-3 mb-0">
                      <li>
                        <div className="d-flex mt-5">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold"> Name: </h6>
                            <span className="text-muted">
                              {forms.firstName}/{forms.lastName}{" "}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">
                              Doctor Employee Id:
                            </h6>
                            <span className="text-muted">
                              {" "}
                              {forms.doctorEmployId}
                            </span>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="d-flex">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">Phone:</h6>
                            {forms.phone}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">Email:</h6>
                            {forms.email}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">
                              Date of Joining:
                            </h6>
                            {forms.joinDate}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex ">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">Experience :</h6>
                            <span className="text-muted">
                              {" "}
                              {forms.experience}
                            </span>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="d-flex ">
                          <i className="bx bx-chevron-right font-size-18 text-primary"></i>
                          <div className="ms-3">
                            <h6 className="mb-1 fw-semibold">Status :</h6>
                            <span className="text-muted">Active</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col lg={8}>
                    <Row>
                      <Col md={6}>
                        <ul className="verti-timeline list-unstyled">
                          <li className="event-list mt-4">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <h6 className="font-size-14 ">
                                    State / Mci Reg No:
                                  </h6>
                                  <p className="text-muted">
                                    {" "}
                                    {forms.registrationNumber}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <h6 className="font-size-14 mb-1">
                                    Qualification
                                  </h6>
                                  <p className="text-muted">
                                    {forms.doctorQualification}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <h6 className="font-size-14 ">Speciality:</h6>
                                  <p className="text-muted">
                                    {forms.speciality}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>

                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <h6 className="font-size-14 ">
                                    Field of Medicine{" "}
                                  </h6>
                                  <p className="text-muted">
                                    {forms.filedOfMedicine}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <h6 className="font-size-14 mb-1">
                                    {" "}
                                    Tariff / Cost
                                  </h6>
                                  <p className="text-muted">
                                    {forms.tarrifPrice}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <h6 className="font-size-14 mb-1">
                                    {" "}
                                    Location in HCU
                                  </h6>
                                  <p className="text-muted">
                                    Room No : {forms.locationInHoc}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <h6 className="font-size-14 mb-1">
                                    {" "}
                                    Description
                                  </h6>
                                  <p className="text-muted">
                                    {forms.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>{" "}
                      <Col md={6} className="mt-3">
                        <ul className="verti-timeline list-unstyled">
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <h6 className="font-size-14 mb-1">
                                    State Registation Certificate
                                  </h6>
                                  <p className="text-muted">
                                    {forms1.map((data, key) => (
                                      <img
                                        key={key}
                                        src={URLS.Base + data}
                                        height="100px"
                                      />
                                    ))}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>{" "}
        </div>
      </React.Fragment>
    </div>
  )
}

export default DoctorView
