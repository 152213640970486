import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"

function AddVendors() {
  const [form, setform] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const [inputList, setInputList] = useState([{ info: "", advantage: "" }])

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { info: "", advantage: "" }])
  }
  const history = useHistory()
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Time Schedule"
            breadcrumbItem="Add Home Tests Making "
          />

          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Row>
                    <h6
                      style={{ color: "#000", fontWeight: "bold" }}
                      className="mb-4"
                    >
                     Add Home Tests Making
                    </h6>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Test Name
                          <span className="text-danger">*</span>
                        </Label>
                        <select
                          className="form-select"
                          required
                          onChange={e => {
                            handlechange(e)
                          }}
                        >
                          <option value="">Select</option>
                          <option value="">Blood Test / Apollo112</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Price <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Price"
                          required
                          disabled
                          value={form.t2}
                          name="t2"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>

                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Employee Name
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Employee Name"
                          required
                          value={form.t1}
                          name="t1"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Employee Id<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Employee Id"
                          required
                          value={form.t2}
                          name="t2"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Date<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Date"
                          required
                          value={form.t2}
                          name="t2"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <Label for="basicpill-firstname-input1">
                        Apply Same Slot Next 7 Days{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <select
                        className="form-select"
                        required
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </Col>

                    <Col md={8}>
                      <div>
                        <Row>
                          {inputList.map((x, i) => {
                            return (
                              <>
                                <Row>
                                  <div key={i} className="box row">
                                    <Col md="5" sm="12" className="mb-1">
                                      <Label>From Time</Label>
                                      <Input
                                        type="time"
                                        name="info"
                                        placeholder="Enter From Time"
                                        value={x.info}
                                        onChange={e => handleInputChange(e, i)}
                                      />
                                    </Col>

                                    <Col md="5" sm="12" className="mb-1">
                                      <Label>To Time</Label>
                                      <Input
                                        type="time"
                                        name="advantage"
                                        placeholder="Enter To Time"
                                        value={x.advantage}
                                        onChange={e => handleInputChange(e, i)}
                                      />
                                    </Col>

                                    <Col sm="2">
                                      <Label className="mt-1"></Label>
                                      <div className="btn-box">
                                        {inputList.length !== 1 && (
                                          <button
                                            className="mr10 btn btn-outline-danger btn-sm m-1 mt-3"
                                            type="button"
                                            onClick={() => handleRemoveClick(i)}
                                          >
                                            Remove{" "}
                                            <i className="bx bx-x-circle"></i>
                                          </button>
                                        )}
                                        {inputList.length - 1 === i && (
                                          <button
                                            className="btn btn-sm btn-outline-info m-1 mt-3"
                                            onClick={handleAddClick}
                                          >
                                            Add{" "}
                                            <i className="bx bx-plus-circle"></i>
                                          </button>
                                        )}
                                      </div>
                                    </Col>
                                  </div>
                                </Row>
                              </>
                            )
                          })}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Row>
                <div style={{ float: "right" }}>
                  <Button className="m-1 mt-5" color="info" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
