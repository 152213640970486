import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import Select from "react-select"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles1, setselectedFiles1] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([])

  function handleSelect(details) {
    setSelectedOptions(details)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  //Adddelear api integration

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("company_name", form.company_name)
    dataArray.append("company_mobile_number", form.company_mobile_number)
    dataArray.append("trading_number", form.trading_number)
    dataArray.append("vat", form.vat)
    dataArray.append("address", form.address)
    dataArray.append("supportEmail", form.supportEmail)
    dataArray.append("bookingEmail", form.bookingEmail)
    dataArray.append("name", form.name)
    dataArray.append("email", form.email)
    dataArray.append("password", form.password)
    dataArray.append("phone", form.phone)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("trading_image", selectedFiles[i])
    }

    for (let i = 0; i < selectedFiles1.length; i++) {
      dataArray.append("logo", selectedFiles1[i])
    }

    axios
      .post("", dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Vendorslist")
            clearForm()
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      company_name: "",
      company_mobile_number: "",
      trading_number: "",
      trading_image: "",
      vat: "",
      address: "",
      supportEmail: "",
      bookingEmail: "",
      cityId: "",
      name: "",
      email: "",
      password: "",
      phone: "",
      logo: "",
    })
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes1(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes1(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [cities, setcities] = useState([])
  // useEffect(() => {
  //   getallCities()
  // }, [])

  const getallCities = () => {
    var token = datas
    axios
      .get(
        "http://103.186.185.77:5021/api/v1/superadmin/city/allactivecities",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcities(res.data.data)
      })
  }

  const [inputList, setInputList] = useState([
    { planAdvantage: "", planInfo: "" },
  ])

  console.log(inputList)

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { planAdvantage: "", planInfo: "" }])
  }

  const options = [
    { value: "BUN (Blood Urea Nitrogen)", label: "BUN (Blood Urea Nitrogen) / (Apollotst900)" },
    {
      value: "CA 19-9 Blood Test (Pancreatic Cancer) ",
      label: "CA 19-9 Blood Test (Pancreatic Cancer) / (Apollotst600)",
    },
    {
      value: "CA-125 Blood Test (Ovarian Cancer)",
      label: "CA-125 Blood Test (Ovarian Cancer) / (Apollotst200)",
    },
    // Add more options as needed
  ]

  const handleChange = selectedOptions => {
    // Do something with the selected options
    console.log(selectedOptions)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Test" breadcrumbItem="Book a test" />
          <Row>
            <Col xl="12">
              <div style={{ float: "right" }}>
                <Button
                  onClick={history.goBack}
                  className="mb-3  m-1 "
                  color="primary"
                  style={{ float: "right" }}
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <h5 className="mb-3">Add Booking</h5>
                <Row className="mt-2">
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter  Name"
                        required
                        value="sameer"
                        name="title"
                        disabled
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Phone Number <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Phone Number"
                        required
                        value="8855221144"
                        name="title"
                        disabled
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Age <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Age"
                        required
                        value="21"
                        name="title"
                        disabled
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <Label for="basicpill-firstname-input1">
                      Gender <span className="text-danger">*</span>
                    </Label>
                    <select
                      className="form-select"
                      required
                      disabled
                      onChange={e => {
                        handlechange(e)
                      }}
                    >
                      <option value="Male">Male</option>
                    </select>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        Tests <span className="text-danger">*</span>
                      </Label>
                      <Select
                        isMulti
                        options={options}
                        onChange={handleChange}
                      />{" "}
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        Price <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Price"
                        required
                        disabled
                        value={form.title}
                        name="title"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <div style={{ float: "right" }} className="mt-4">
                  <Button className="m-1" color="info" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
