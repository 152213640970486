import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

const Staff = () => {
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)
  const [isLoading, setIsLoading] = useState(true)
  const [form, setform] = useState([])
  const [users, setusers] = useState([])
  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        URLS.GetStaff,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.staff)
        setIsLoading(false)
      })
  }

  const custsearch = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const token = datas
    console.log(token)
    axios
      .post(
        URLS.GetStaffSearch + `${e.target.value}`,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setusers(res.data.staff)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Add = () => {
    var token = datas

    const dataArray = {
      hospitalId: datasd,
      email: form.email,
      mobileNumber: form.mobileNumber,
      password: form.password,
      hospitaldepartmentId: form.hospitaldepartmentId,
      hospitalroleId: form.hospitalroleId,
      employeName: form.employeName,
      employeId: form.employeId,
    }

    axios
      .post(URLS.AddStaff, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            console.log(res.data)
            toast(res.data.message)
            setform("")
            Get()
            setshow(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formsubmit = e => {
    e.preventDefault()
    Add()
  }

  const Update = () => {
    var token = datas
    const dataArray = {
      email: form1.email,
      mobileNumber: form1.mobileNumber,
      password: form1.password,
      hospitaldepartmentId: form1.hospitaldepartmentId,
      hospitalroleId: form1.hospitalroleId,
      employeName: form1.employeName,
      employeId: form1.employeId,
    }

    axios
      .put(URLS.EditStaff + form1._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            console.log(res.data)
            toast(res.data.message)
            setform1("")
            Get()
            setshow1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formeditsubmit = e => {
    e.preventDefault()
    Update()
  }

  const usedata = datal => {
    setshow1(true)
    setform1(datal)
  }

  useEffect(() => {
    Get()
    GetRoles()
  }, [])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = users.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(users.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = datau => {
    var token = datas
    var remid = datau._id
    axios
      .delete(URLS.DeleteStaff + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            Get()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [dep, setdep] = useState([])

  useEffect(() => {
    Getalldep()
  }, [])

  const Getalldep = () => {
    var token = datas
    axios
      .post(
        URLS.GetDepartment,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setdep(res.data.hsptldpts)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }
  const [role, setrole] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasd = data.hospitalId

  const Optionchangess = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const Optionchangess1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const GetRoles = () => {
    var token = datas
    axios
      .post(
        URLS.GetRole,
        { hospitalId: datasd },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setrole(res.data.role)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Staff" />

          <Row>
            <Col>
              {show == true ? (
                <Card className="p-4">
                  <Form
                    onSubmit={e => {
                      formsubmit(e)
                    }}
                  >
                    <h5>Add New Staff</h5>
                    <Row>
                      <Col md="3">
                        <Label>Employee Id</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="employeId"
                          onChange={e => {
                            handleChange(e)
                          }}
                          pattern="[a-zA-Z0-9\s.,$@%*]+"
                          value={form.employeId}
                          required
                          type="text"
                          placeholder="Enter Employee Id"
                        />
                      </Col>
                      <Col md="3">
                        <Label>Employee Name</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="employeName"
                          onChange={e => {
                            handleChange(e)
                          }}
                          pattern="[a-zA-Z0-9\s.,$@%*]+"
                          value={form.employeName}
                          required
                          type="text"
                          placeholder="Enter Employee Name"
                        />
                      </Col>
                      <Col md="3">
                        <Label>Email Id</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="email"
                          onChange={e => {
                            handleChange(e)
                          }}
                          value={form.email}
                          required
                          type="email"
                          placeholder="Enter Email"
                        />
                      </Col>
                      <Col md="3">
                        <Label>Mobile No</Label>{" "}
                        <span className="text-danger">*</span>
                        <div className="input-group mb-3">
                          <Input
                            name="mobileNumber"
                            onChange={e => {
                              handleChange(e)
                            }}
                            value={form.mobileNumber}
                            required
                            type="text"
                            minLength="10"
                            maxLength="10"
                            pattern="[0-9]+"
                            className="form-control"
                            placeholder="Enter Mobile No"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <Label>Password</Label>{" "}
                        <span className="text-danger">*</span>
                        <Input
                          name="password"
                          onChange={e => {
                            handleChange(e)
                          }}
                          type="text"
                          value={form.password}
                          required
                          placeholder="Enter password"
                        />
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label> Department</Label>{" "}
                          <span className="text-danger">*</span>
                          <select
                            value={form.hospitaldepartmentId}
                            name="hospitaldepartmentId"
                            required
                            onChange={e => {
                              Optionchangess(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {dep.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.departmentName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label> Roles </Label>{" "}
                          <span className="text-danger">*</span>
                          <select
                            name="hospitalroleId"
                            onChange={e => {
                              handleChange(e)
                            }}
                            value={form.hospitalroleId}
                            required
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {role.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.roleName}
                                </option>
                              )
                            })}
                          </select>{" "}
                        </div>
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button type="submit" color="success m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </Card>
              ) : (
                ""
              )}
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col>
                          <Button
                            onClick={() => {
                              setshow(!show)
                            }}
                            color="primary"
                          >
                            New Staff <i className="bx bx-user-plus"></i>
                          </Button>
                        </Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form.search}
                              onChange={custsearch}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                        <Table hover bordered responsive>
                          <thead>
                            <tr>
                              <th>Sl No</th>
                              <th>Employee Id</th>
                              <th>Employee Name</th>
                              <th>Email</th>
                              <th>Mobile No</th>
                              <th>Department</th>
                              <th>Role</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key}>
                                <th scope="row">
                                  {(pageNumber - 1) * 20 + key + 21}
                                </th>
                                <td>{data.employeId}</td>
                                <td>{data.employeName}</td>
                                <td>{data.email}</td>
                                <td>{data.mobileNumber}</td>
                                <td>{data.departmentName}</td>
                                <td>{data.roleName}</td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      usedata(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="success"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="bx bx-edit"
                                    ></i>
                                  </Button>

                                  <Button
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="danger"
                                    onClick={() => {
                                      manageDelete(data)
                                    }}
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="bx bx-trash"
                                    ></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Col sm="12">
                          <div
                            className="d-flex mt-3 mb-1"
                            style={{ float: "right" }}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </Col>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
      <Modal size="lg" isOpen={show1} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Edit Staff Details</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              formeditsubmit(e)
            }}
          >
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label>Employee Id</Label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    name="employeId"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    pattern="[a-zA-Z0-9\s.,$@%*]+"
                    value={form1.employeId}
                    required
                    type="text"
                    placeholder="Enter Employee Id"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label>Employee Name</Label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    name="employeName"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    pattern="[a-zA-Z0-9\s.,$@%*]+"
                    value={form1.employeName}
                    required
                    type="text"
                    placeholder="Enter Employee Name"
                  />
                </div>
              </Col>
              <Col md="6">
                {" "}
                <div className="mb-3">
                  <Label>Email Id</Label> <span className="text-danger">*</span>
                  <Input
                    name="email"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    value={form1.email}
                    required
                    type="email"
                    placeholder="Enter Email"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label>Mobile No</Label>{" "}
                  <span className="text-danger">*</span>
                  <div className="input-group mb-3">
                    <Input
                      name="mobileNumber"
                      onChange={e => {
                        handleChange1(e)
                      }}
                      value={form1.mobileNumber}
                      required
                      type="text"
                      minLength="10"
                      maxLength="10"
                      pattern="[0-9]+"
                      className="form-control"
                      placeholder="Enter Mobile No"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label> Department</Label>{" "}
                  <span className="text-danger">*</span>
                  <select
                    value={form1.hospitaldepartmentId}
                    name="hospitaldepartmentId"
                    required
                    onChange={e => {
                      handleChange1(e)
                    }}
                    className="form-select"
                  >
                    <option value="">Select</option>
                    {dep.map((data, key) => {
                      return (
                        <option key={key} value={data._id}>
                          {data.departmentName}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label> Roles </Label> <span className="text-danger">*</span>
                  <select
                    name="hospitalroleId"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    value={form1.hospitalroleId}
                    required
                    className="form-select"
                  >
                    <option value="">Select</option>
                    {role.map((data, key) => {
                      return (
                        <option key={key} value={data._id}>
                          {data.roleName}
                        </option>
                      )
                    })}
                  </select>{" "}
                </div>
              </Col>
            </Row>
            <div className="text-end mt-3">
              <Button type="submit" color="success m-1" outline>
                Submit <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setshow1(!show1)
                }}
                color="danger m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Staff
